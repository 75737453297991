import { gql } from '@apollo/client';

export const ExportZevTimeSeriesDataMutation = gql`
  mutation ExportZevTimeSeries(
    $zevId: ID!
    $start: DateTime!
    $end: DateTime!
    $valueType: ExportTimeSeriesValueType!
  ) {
    ekaExportTimeSeries(zevId: $zevId, start: $start, end: $end, valueType: $valueType) {
      __typename
      ... on RequestSubmitted {
        ok
      }
      ... on AccessDeniedError {
        errorCode
      }
      ... on NotFoundError {
        errorCode
      }
      ... on ExportTimeSeriesBadRequestError {
        errors {
          errorCode
        }
      }
    }
  }
`;
