import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { EkaPremiseUpdateErrorCode, ErrorCode } from '@typings/graphql-global';
import { ExtractErrorCodesFromCommonApiCreateUpdateQuery } from '@utils/util.types';
import {
  UpdatePremise_ekaUpdatePremise,
  UpdatePremise_ekaUpdatePremise_OkResult,
} from '../../queries/__generated__/UpdatePremise';
import { updatePremiseMutation } from '../../queries/updatePremise.mutation';

export type EkaPremiseUpdatePayload = {
  utilizationUnitId: string;
};

export type EkaPremiseUpdateErrorCodes =
  | EkaPremiseUpdateErrorCode
  | ExtractErrorCodesFromCommonApiCreateUpdateQuery<UpdatePremise_ekaUpdatePremise>
  | ErrorCode.NO_UPDATE_PERMISSION
  | ErrorCode.NOT_FOUND
  | 'Error';

export type EkaPremiseUpdateResponse = EkaPremiseUpdateErrorCodes | UpdatePremise_ekaUpdatePremise_OkResult;

export const updateEkaPremise = createAsyncThunk<
  EkaPremiseUpdateResponse,
  EkaPremiseUpdatePayload,
  { rejectValue: EkaPremiseUpdateErrorCodes }
>('ekaPremise/update', async ({ utilizationUnitId }, { rejectWithValue }) => {
  const apolloClient = getApolloClient();

  try {
    const { data } = await apolloClient.mutate({
      mutation: updatePremiseMutation,
      variables: { utilizationUnitId },
      fetchPolicy: 'no-cache',
    });

    // Check for success response
    if (data?.ekaUpdatePremise?.__typename === 'OkResult') {
      return data.ekaUpdatePremise;
    } else {
      return rejectWithValue(data?.ekaUpdatePremise['__typename'] ?? 'Error');
    }
  } catch (error) {
    return rejectWithValue('Error');
  }
});
