import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { ekaActions } from '@eka/redux/eakSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorCode, ImportBadRequestErrorCode, TimeRange } from '@typings/graphql-global';
import { ImportMultiAssetFile_importMultiAssetFile_ImportResult } from '../../queries/__generated__/ImportMultiAssetFile';
import { importMultiAssetFileMutation } from '../../queries/importMultiAssetFile.mutation';

export type ImportMultiAssetFilePayload = {
  csvfile: File;
  timeRange: TimeRange;
};

export type ImportMultiAssetFileErrorCodes =
  | ErrorCode.NOT_FOUND
  | ErrorCode.NO_CREATION_PERMISSION
  | ErrorCode.READ_TIMED_OUT
  | ImportBadRequestErrorCode.ATTRIBUTE_TYPE_ID_INVALID
  | ImportBadRequestErrorCode.FILE_DATA_INVALID
  | ImportBadRequestErrorCode.FILE_FORMAT_INVALID
  | ImportBadRequestErrorCode.FILE_SIZE_LIMIT_EXCEEDED
  | ImportBadRequestErrorCode.IMPORT_NOT_SUPPORTED
  | ImportBadRequestErrorCode.TIME_RANGE_INVALID
  | 'NotFoundError'
  | 'AccessDeniedError'
  | 'ReadTimedOutError'
  | 'Error';

export const importMultiAssetFile = createAsyncThunk<
  ImportMultiAssetFile_importMultiAssetFile_ImportResult,
  ImportMultiAssetFilePayload,
  { rejectValue: ImportMultiAssetFileErrorCodes }
>('multiAssetFile/import', async ({ csvfile, timeRange }, { rejectWithValue, dispatch }) => {
  const apolloClient = getApolloClient();

  try {
    const { data } = await apolloClient.mutate({
      mutation: importMultiAssetFileMutation,
      variables: { csvFile: csvfile, timeRange },
    });

    if (data?.importMultiAssetFile.__typename === 'ImportResult') {
      return data.importMultiAssetFile;
    } else {
      return rejectWithValue(
        (data?.importMultiAssetFile?.badRequestErrorCode ?? { __typename: 'Error' }) as ImportMultiAssetFileErrorCodes
      );
    }
  } catch (error) {
    // Timeout error handling
    if (error.message.includes('java.net.SocketTimeoutException')) {
      dispatch(ekaActions.setReadTimedOutMessage({ errorCode: 'ReadTimedOutError', message: error.message }));
    } else {
      dispatch(ekaActions.setReadTimedOutMessage({}));
    }

    return rejectWithValue('Error');
  } finally {
    dispatch(ekaActions.resetImportMultiAssetFile());
  }
});
