import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { EkaExportZevBillingDataErrorCode, ErrorCode, HighCorrectionFactorOption } from '@typings/graphql-global';
import { ExportZevBillingData_ekaExportZevBillingData } from '../../queries/__generated__/ExportZevBillingData';
import { ExportZevBillingDataMutation } from '../../queries/exportBillingData.mutation';
import { ekaActions } from '../eakSlice';

export type ExportZevBillingDataPayload = {
  zevId: string;
  start: string;
  end: string;
  highCorrectionFactorOption: HighCorrectionFactorOption;
};
export type ExportZevBillingDataErrorCodes =
  | EkaExportZevBillingDataErrorCode.INTEGRATION_DISABLED
  | EkaExportZevBillingDataErrorCode.INVALID_END
  | EkaExportZevBillingDataErrorCode.INVALID_LAST_EXPORT
  | EkaExportZevBillingDataErrorCode.INVALID_START
  | EkaExportZevBillingDataErrorCode.UNKNOWN_ERROR
  | 'NotFoundError'
  | 'AccessDeniedError'
  | 'Error'
  | ErrorCode.NOT_FOUND
  | ErrorCode.NO_UPDATE_PERMISSION;

export type EkaExportZevBillingDataResponse =
  | ExportZevBillingDataErrorCodes
  | ExportZevBillingData_ekaExportZevBillingData;

export const exportZevBillingData = createAsyncThunk<
  EkaExportZevBillingDataResponse,
  ExportZevBillingDataPayload,
  { rejectValue: ExportZevBillingDataErrorCodes }
>(
  'zevAsset/exportZevBillingData',
  async ({ zevId, start, end, highCorrectionFactorOption }, { rejectWithValue, dispatch }) => {
    const apolloClient = getApolloClient();

    try {
      const { data } = await apolloClient.mutate({
        mutation: ExportZevBillingDataMutation,
        variables: { zevId, start, end, highCorrectionFactorOption },
        fetchPolicy: 'no-cache',
      });
      if (data?.ekaExportZevBillingData.__typename === 'OkResult') {
        return data.ekaExportZevBillingData;
      } else if (data?.ekaExportZevBillingData.__typename !== 'OkResult') {
        return rejectWithValue(data.ekaExportZevBillingData as ExportZevBillingDataErrorCodes);
      } else {
        return rejectWithValue('Error' as ExportZevBillingDataErrorCodes);
      }
    } catch (error) {
      return rejectWithValue('Error' as ExportZevBillingDataErrorCodes);
    } finally {
      dispatch(ekaActions.resetExportBillingData());
    }
  }
);
