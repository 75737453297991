import { gql } from '@apollo/client';

export const updateInstallationMutation = gql`
  mutation UpdateInstallation($balancingPointId: ID!) {
    ekaUpdateInstallation(balancingPointId: $balancingPointId) {
      __typename
      ... on OkResult {
        ok
      }
      ... on EkaInstallationUpdateError {
        errors {
          errorCode
          message
        }
      }
      ... on AccessDeniedError {
        errorCode
      }
      ... on NotFoundError {
        errorCode
      }
    }
  }
`;
