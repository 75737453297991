import { gql } from '@apollo/client';

export const amendSapPremiseIdsMutation = gql`
  mutation AmendSapPremiseIds($zevId: String!, $csvFile: Upload!) {
    ekaAmendSapPremiseIds(zevId: $zevId, csvFile: $csvFile) {
      __typename
      ... on EkaAmendSapPremiseIdsSuccess {
        file {
          name
          mimeType
          content
        }
        warnings {
          warningCode
          utilizationUnitName
          message
        }
      }
      ... on EkaAmendSapPremiseIdsError {
        errors {
          errorCode
          message
        }
      }
      ... on AccessDeniedError {
        errorCode
      }
      ... on NotFoundError {
        errorCode
      }
    }
  }
`;
