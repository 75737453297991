import {
  EkaConfig,
  EkaConfigBalancingPoint,
  EkaConfigBalancingPointConsumption,
  EkaConfigBalancingPointOwner,
  EkaConfigBalancingPointTypeNames,
  EkaConfigBalancingPointTypes,
  EkaConfigUtilizationUnit,
  EkaConfigUtilizationUnitTypeNames,
  EkaConfigUtilizationUnitTypes,
  EkaConfigOwner,
  EkaConfigTenant,
} from '@eka/eka.types';

export class EkaConfigModel implements EkaConfig {
  private readonly _assetTypeIdToConfigKey = new Map<string, string>();

  private readonly _raw: EkaConfig;

  constructor(data: EkaConfig) {
    this._raw = data;

    const unwrapConfigKeys = <K extends 'balancingPoint' | 'utilizationUnit', T extends EkaConfig[K]>(
      scopeName: K
    ): void => {
      if (scopeName in data) {
        const scope = data[scopeName] as T;
        Object.keys(scope).forEach((k) => {
          const key = k as keyof T;
          const value = scope[key] as unknown as EkaConfigBalancingPoint | EkaConfigUtilizationUnit;
          this._assetTypeIdToConfigKey.set(value.assetTypeId, key as string);
        });
      }
    };
    unwrapConfigKeys('balancingPoint');
    unwrapConfigKeys('utilizationUnit');
  }

  get version(): EkaConfig['version'] {
    return this._raw.version;
  }

  get relationTypeId(): EkaConfig['relationTypeId'] {
    return this._raw.relationTypeId;
  }

  get zev(): EkaConfig['zev'] {
    return this._raw.zev;
  }

  get property(): EkaConfig['property'] {
    return this._raw.property;
  }

  get utilizationUnit(): EkaConfig['utilizationUnit'] {
    return this._raw.utilizationUnit;
  }

  get balancingPoint(): EkaConfig['balancingPoint'] {
    return this._raw.balancingPoint;
  }

  get billingExportConfig(): EkaConfig['billingExportConfig'] {
    return this._raw.billingExportConfig;
  }

  get tenant(): EkaConfig['tenant'] {
    return this._raw.tenant;
  }

  get owner(): EkaConfig['owner'] {
    return this._raw.owner;
  }

  get subMeter(): EkaConfig['subMeter'] {
    return this._raw.subMeter;
  }

  isBalancingPointAssetType(assetTypeId: string): boolean {
    return this.getBalancingPointAssetTypeIds().includes(assetTypeId);
  }

  isBalancingPointWithIntegrationProperties(
    config: EkaConfigBalancingPoint
  ): config is EkaConfigBalancingPointConsumption | EkaConfigBalancingPointOwner {
    return 'sapIntegration' in config;
  }

  isUtilizationUnitAssetType(assetTypeId: string): boolean {
    return this.getUtilizationUnitAssetTypeIds().includes(assetTypeId);
  }

  getBalancingPointConfigByAssetTypeId(assetTypeId: string): EkaConfigBalancingPoint | undefined {
    const key = this._assetTypeIdToConfigKey.get(assetTypeId);
    return this.balancingPoint[key as EkaConfigBalancingPointTypeNames];
  }

  getUtilizationUnitConfigByAssetTypeId(assetTypeId: string): EkaConfigUtilizationUnit | undefined {
    const key = this._assetTypeIdToConfigKey.get(assetTypeId);
    return this.utilizationUnit[key as EkaConfigUtilizationUnitTypeNames];
  }

  getUtilizationUnitConfigKeyByAssetTypeId(assetTypeId: string): keyof EkaConfigUtilizationUnitTypes {
    return this._assetTypeIdToConfigKey.get(assetTypeId) as keyof EkaConfigUtilizationUnitTypes;
  }

  getBalancingPointConfigKeyByAssetTypeId(assetTypeId: string): keyof EkaConfigBalancingPointTypes {
    return this._assetTypeIdToConfigKey.get(assetTypeId) as keyof EkaConfigBalancingPointTypes;
  }

  getAssignedUserConfigByAssetTypeId(assetTypeId: string): EkaConfigOwner | EkaConfigTenant | undefined {
    return assetTypeId === this.owner.assetTypeId ? this.owner : this.tenant;
  }

  private getBalancingPointTypeConfigKeys(): string[] {
    return Object.keys(this.balancingPoint);
  }

  private getUtilizationUnitTypeConfigKeys(): string[] {
    return Object.keys(this.utilizationUnit);
  }

  private getBalancingPointAssetTypeIds(): string[] {
    return this.getBalancingPointTypeConfigKeys().map((configKey) => {
      return this.balancingPoint[configKey as keyof EkaConfigBalancingPointTypes].assetTypeId;
    });
  }

  private getUtilizationUnitAssetTypeIds(): string[] {
    return this.getUtilizationUnitTypeConfigKeys().map((configKey) => {
      return this.utilizationUnit[configKey as keyof EkaConfigUtilizationUnitTypes].assetTypeId;
    });
  }
}
