import { AttributeValue } from '@teci/ecockpit-frontend-gateway-api';

/**
 * finds an attribute in a given attribute set by a provided id
 */
export const getAttributeValueById = (
  attributeValueId: string,
  attributeValues: AttributeValue[]
): AttributeValue | undefined => {
  return attributeValues.find((attributeValue) => attributeValue.attributeType.id === attributeValueId)?.value;
};
