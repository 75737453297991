import { Model } from '@utils/model.utils';
import { Expose } from 'class-transformer';
import { ZevAssetDetails_asset as ZevAssetSchema } from '../queries/__generated__/ZevAssetDetails';

export class ZevAssetModel extends Model.fromSchema<ZevAssetSchema>() {
  private readonly _attributes = new Map<string, string>();

  @Expose()
  get id(): string {
    return this._raw.id;
  }

  @Expose()
  get name(): string {
    return this._raw.name;
  }

  @Expose()
  get serviceName(): string {
    return this._raw.type.name;
  }

  @Expose()
  get connectionObject(): string | undefined {
    return this._attributes?.get(this._config.zev.connectionObjectIdAttributeTypeId);
  }

  @Expose()
  get computationStartDate(): Date | undefined {
    const date = this._attributes?.get(this._config.zev.computationStartDateAttributeTypeId);
    return !date ? undefined : new Date(date);
  }

  @Expose()
  get mainEnergyManagerGuid(): string | undefined {
    return this._attributes?.get(this._config.zev.mainEnergyManagerGuidAttributeTypeId);
  }

  withData(data: ZevAssetSchema): this {
    // prepare the attributes map once initially
    data.attributeValues.forEach(({ attributeType, value }) => this._attributes.set(attributeType.id, value));

    // call parent method which will return the locked instance
    return super.withData(data);
  }
}
