import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { EkaConfig } from '../../eka.types';
import { PropertyAssetModel } from '../../models/PropertyAsset.model';
import { propertyAssetsQuery } from '../../queries/propertyAssets.query';

export const fetchPropertyAssets = createAsyncThunk<PropertyAssetModel[], { config: EkaConfig }>(
  'propertyAssets/fetch',
  async ({ config }, { rejectWithValue }) => {
    const apolloClient = getApolloClient();

    const assetTypeIdConstraint = {
      assetTypeId: config.property.assetTypeId,
      assetTypeIds: null,
    };

    const assetSearchConstraints = [
      {
        constraintType: 'ASSET_TYPE_ID',
        assetTypeIdConstraint,
        childrenAssetTypeIdConstraint: null,
        parentConstraint: null,
        assetSearchNameSubstringConstraint: null,
      },
    ];

    try {
      const { data } = await apolloClient.query({
        query: propertyAssetsQuery,
        variables: { constraints: assetSearchConstraints },
        fetchPolicy: 'no-cache',
      });
      if (data?.assets.__typename === 'AssetsResult') {
        return data.assets.assetsGroupedByAssetType.flatMap(({ assets }) =>
          assets.map((asset) => new PropertyAssetModel(config).withData(asset))
        );
      } else {
        return rejectWithValue({ __typename: 'Error' });
      }
    } catch (error) {
      return rejectWithValue({ __typename: 'Error', message: error.message });
    }
  }
);
