import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { EkaGenerateBalancingPointDesignationErrorCode, ErrorCode } from '@typings/graphql-global';
import {
  GenerateBalancingPointDesignation,
  GenerateBalancingPointDesignation_ekaGenerateBalancingPointDesignation,
  GenerateBalancingPointDesignation_ekaGenerateBalancingPointDesignation_EkaGenerateBalancingPointDesignationSuccess,
  GenerateBalancingPointDesignationVariables,
} from '../../queries/__generated__/GenerateBalancingPointDesignation';
import { generateBalancingPointDesignationMutation } from '../../queries/generateBalancingPointDesignation.mutation';
import { ExtractErrorCodesFromCommonApiCreateUpdateQuery } from '../../utils/util.types';

type BalancingPointDesignationPayload = {
  propertyId: string;
};

export type BalancingPointDesignationErrorCodes =
  | EkaGenerateBalancingPointDesignationErrorCode
  | ExtractErrorCodesFromCommonApiCreateUpdateQuery<GenerateBalancingPointDesignation_ekaGenerateBalancingPointDesignation>
  | ErrorCode.NOT_FOUND
  | ErrorCode.NO_CREATION_PERMISSION
  | 'NotFoundError'
  | 'AccessDeniedError'
  | 'Error';

export type BalancingPointDesignationResult =
  | BalancingPointDesignationErrorCodes
  | GenerateBalancingPointDesignation_ekaGenerateBalancingPointDesignation_EkaGenerateBalancingPointDesignationSuccess;

export const generateBalancingPointDesignation = createAsyncThunk<
  BalancingPointDesignationResult,
  BalancingPointDesignationPayload
>('assets/generateBalancingPointDesignation', async (payload, { rejectWithValue }) => {
  const { propertyId } = payload;
  const client = getApolloClient();
  try {
    const { data, errors } = await client.mutate<
      GenerateBalancingPointDesignation,
      GenerateBalancingPointDesignationVariables
    >({
      mutation: generateBalancingPointDesignationMutation,
      variables: { propertyId },
    });

    // Ensure data is not falsy
    if (!data) {
      throw new Error('Mutation returned no data');
    }

    // Handle mutation result
    if (data.ekaGenerateBalancingPointDesignation.__typename === 'EkaGenerateBalancingPointDesignationSuccess') {
      return data.ekaGenerateBalancingPointDesignation?.balancingPointDesignation;
    } else if (errors && errors.length) {
      return rejectWithValue('Error' as BalancingPointDesignationErrorCodes);
    } else {
      return rejectWithValue(
        data.ekaGenerateBalancingPointDesignation['__typename'] as BalancingPointDesignationErrorCodes
      );
    }
  } catch (error) {
    return rejectWithValue(error.message as BalancingPointDesignationErrorCodes);
  }
});
