import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorCode, ExportTimeSeriesBadRequestErrorCode, ExportTimeSeriesValueType } from '@typings/graphql-global';
import { ExportZevTimeSeries_ekaExportTimeSeries } from '../../queries/__generated__/ExportZevTimeSeries';
import { ExportZevTimeSeriesDataMutation } from '../../queries/exportTimeSeries.mutation';

export type ExportZevTimeSeriesPayload = {
  zevId: string;
  start: string;
  end: string;
  valueType: ExportTimeSeriesValueType;
};

export type ExportZevTimeSeriesErrorCodes =
  | ExportTimeSeriesBadRequestErrorCode.JOB_IS_RUNNING
  | ExportTimeSeriesBadRequestErrorCode.TIME_RANGE_INVALID
  | 'NotFoundError'
  | 'AccessDeniedError'
  | 'Error'
  | ErrorCode.NOT_FOUND
  | ErrorCode.NO_UPDATE_PERMISSION;

export type EkaExportZevTimeSeriesResponse = ExportZevTimeSeriesErrorCodes | ExportZevTimeSeries_ekaExportTimeSeries;

export const exportZevTimeSeries = createAsyncThunk<
  any,
  ExportZevTimeSeriesPayload,
  { rejectValue: ExportZevTimeSeriesErrorCodes }
>('exportZevTimeSeries/export', async ({ zevId, start, end, valueType }, { rejectWithValue }) => {
  const apolloClient = getApolloClient();

  try {
    const { data } = await apolloClient.mutate({
      mutation: ExportZevTimeSeriesDataMutation,
      variables: { zevId, start, end, valueType },
      fetchPolicy: 'no-cache',
    });

    if (data?.ekaExportTimeSeries?.__typename === 'RequestSubmitted') {
      return { success: true, data: data.ekaExportTimeSeries };
    } else if (data?.ekaExportTimeSeries.__typename !== 'RequestSubmitted') {
      return rejectWithValue(data.ekaExportTimeSeries.errorCode as ExportZevTimeSeriesErrorCodes);
    } else {
      return rejectWithValue('Error');
    }
  } catch (error) {
    return rejectWithValue('Error');
  }
});
