import { gql } from '@apollo/client';

export const createPremiseMutation = gql`
  mutation CreatePremise($utilizationUnitId: ID!) {
    ekaCreatePremise(utilizationUnitId: $utilizationUnitId) {
      __typename
      ... on Premise {
        premiseId
        timestamp
      }
      ... on EkaPremiseCreationError {
        errors {
          errorCode
          message
        }
      }
      ... on NotFoundError {
        __typename
        errorCode
      }
      ... on AccessDeniedError {
        __typename
        errorCode
      }
    }
  }
`;
