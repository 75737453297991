import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { EkaConfig } from '../../eka.types';
import { ZevAssetModel } from '../../models/ZevAsset.model';
import { zevAssetDetailsQuery } from '../../queries/zevAssetDetails.query';

export type ZevAssetPayload = {
  assetId: string;
  config: EkaConfig;
};

export const fetchZevAsset = createAsyncThunk(
  'zevAsset/fetchZevAsset',
  async ({ assetId, config }: ZevAssetPayload, { rejectWithValue }) => {
    try {
      const apolloClient = getApolloClient();
      const { data } = await apolloClient.query({
        query: zevAssetDetailsQuery,
        variables: { assetId },
        fetchPolicy: 'cache-first',
      });

      if (data && data.asset) {
        return new ZevAssetModel(config).withData(data.asset);
      } else {
        return rejectWithValue('ERROR');
      }
    } catch (error) {
      return rejectWithValue('ERROR');
    }
  }
);
