import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorCode } from '@typings/graphql-global';
import { ExtractErrorCodesFromCommonApiCreateUpdateQuery } from '@utils/util.types';
import {
  CreatePremise_ekaCreatePremise,
  CreatePremise_ekaCreatePremise_Premise,
} from '../../queries/__generated__/CreatePremise';
import { createPremiseMutation } from '../../queries/createPremise.mutation';

export type EkaPremiseCreatePayload = {
  utilizationUnitId: string;
};

export type EkaPremiseCreateErrorCodes =
  | ExtractErrorCodesFromCommonApiCreateUpdateQuery<CreatePremise_ekaCreatePremise>
  | ErrorCode.NO_CREATION_PERMISSION
  | ErrorCode.NOT_FOUND
  | 'Error';

export type EkaPremiseCreateResponse = EkaPremiseCreateErrorCodes | CreatePremise_ekaCreatePremise_Premise;

export const createEkaPremise = createAsyncThunk<
  EkaPremiseCreateResponse,
  EkaPremiseCreatePayload,
  { rejectValue: EkaPremiseCreateErrorCodes }
>('ekaPremise/create', async ({ utilizationUnitId }, { rejectWithValue }) => {
  const apolloClient = getApolloClient();

  try {
    const { data } = await apolloClient.mutate({
      mutation: createPremiseMutation,
      variables: { utilizationUnitId },
      fetchPolicy: 'no-cache',
    });

    if (data?.ekaCreatePremise?.__typename === 'Premise') {
      return data.ekaCreatePremise;
    } else {
      return rejectWithValue((data?.ekaCreatePremise['__typename'] ?? 'Error') as EkaPremiseCreateErrorCodes);
    }
  } catch (error) {
    return rejectWithValue('Error');
  }
});
