import { gql } from '@apollo/client';

export const updatePremiseMutation = gql`
  mutation UpdatePremise($utilizationUnitId: ID!) {
    ekaUpdatePremise(utilizationUnitId: $utilizationUnitId) {
      __typename
      ... on OkResult {
        ok
      }
      ... on EkaPremiseUpdateError {
        errors {
          errorCode
          message
        }
      }
      ... on AccessDeniedError {
        errorCode
      }
      ... on NotFoundError {
        errorCode
      }
    }
  }
`;
