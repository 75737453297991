import { gql } from '@apollo/client';

export const updateBalancingPointAssetMutation = gql`
  mutation UpdateBalancingPointAsset($balancingPointId: ID!, $update: EkaBalancingPointUpdate!) {
    ekaUpdateBalancingPoint(balancingPointId: $balancingPointId, update: $update) {
      ... on Asset {
        id
        name
        attributeValues {
          id
          value
          attributeType {
            id
            name
          }
        }
      }
      ... on EkaBalancingPointUpdateError {
        errors {
          __typename
          errorCode
          message
        }
      }
      ... on AccessDeniedError {
        __typename
        errorCode
      }
      ... on NotFoundError {
        __typename
        errorCode
      }
    }
  }
`;
