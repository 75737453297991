import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorCode } from '@typings/graphql-global';
import { ExtractErrorCodesFromCommonApiCreateUpdateQuery } from '@utils/util.types';
import {
  ImportUtilizationUnits,
  ImportUtilizationUnits_ekaImportUtilizationUnits,
  ImportUtilizationUnitsVariables,
} from '../../queries/__generated__/ImportUtilizationUnits';
import { importUtilizationUnitsMutation } from '../../queries/importUtilizationUnits.mutation';

type UtilizationUnitsImportPayload = {
  zevId: string;
  csvFile: File;
};

export type UtilizationUnitsImportErrorCodes =
  | ExtractErrorCodesFromCommonApiCreateUpdateQuery<ImportUtilizationUnits_ekaImportUtilizationUnits>
  | ErrorCode.NOT_FOUND
  | ErrorCode.NO_CREATION_PERMISSION
  | ErrorCode.BAD_REQUEST
  | 'Error';

export const importUtilizationUnits = createAsyncThunk<
  boolean,
  UtilizationUnitsImportPayload,
  { rejectValue: UtilizationUnitsImportErrorCodes }
>('utilizationUnits/import', async ({ zevId, csvFile }: UtilizationUnitsImportPayload, { rejectWithValue }) => {
  const apolloClient = getApolloClient();

  try {
    const { data } = await apolloClient.mutate<ImportUtilizationUnits, ImportUtilizationUnitsVariables>({
      mutation: importUtilizationUnitsMutation,
      variables: { zevId, csvFile },
      fetchPolicy: 'no-cache',
    });

    if (data?.ekaImportUtilizationUnits.__typename === 'RequestSubmitted') {
      return data.ekaImportUtilizationUnits.ok;
    } else {
      return rejectWithValue(data?.ekaImportUtilizationUnits as unknown as UtilizationUnitsImportErrorCodes);
    }
  } catch (error) {
    return rejectWithValue('Error');
  }
});
