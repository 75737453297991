import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PartialByKey } from '@utils/util.types';
import { EkaConfig } from '../../eka.types';
import { UtilizationUnitAssetModel } from '../../models/UtilizationUnitAsset.model';
import {
  UtilizationUnitAsset as UtilizationUnitAssetQuery,
  UtilizationUnitAssetVariables,
} from '../../queries/__generated__/UtilizationUnitAsset';
import { utilizationUnitAssetQuery } from '../../queries/utilizationUnitAsset.query';

export type UtilizationUnitAssetPayload = {
  utilizationUnitAssetId: string | undefined;
  config: EkaConfig;
};

export type UtilizationUnitAssetErrorCodes = 'ERROR'; // None of the usual error types in API

export type UtilizationUnitAssetResponse = UtilizationUnitAssetErrorCodes | UtilizationUnitAssetModel;

export const fetchUtilizationUnitAsset = createAsyncThunk<
  UtilizationUnitAssetModel,
  UtilizationUnitAssetPayload,
  { rejectValue: UtilizationUnitAssetErrorCodes }
>('utilizationUnitAsset/fetch', async ({ utilizationUnitAssetId, config }, { rejectWithValue }) => {
  const client = getApolloClient();

  try {
    const { data } = await client.query<
      UtilizationUnitAssetQuery,
      PartialByKey<UtilizationUnitAssetVariables, 'utilizationUnitAssetId'>
    >({
      query: utilizationUnitAssetQuery,
      variables: { utilizationUnitAssetId },
      fetchPolicy: 'cache-first',
    });

    if (data && data.asset !== null) {
      return data.asset;
    } else {
      return rejectWithValue('ERROR');
    }
  } catch (error) {
    return rejectWithValue('ERROR');
  }
});
