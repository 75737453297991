import { gql } from '@apollo/client';
import { assetChildrenDetailsFragment } from './zevAssetsByProperty.query';

export const zevAssetDetailsQuery = gql`
  query ZevAssetDetails($assetId: ID!) {
    asset(assetId: $assetId) {
      id
      name
      type {
        name
      }
      attributeValues {
        id
        value
        attributeType {
          id
          name
        }
      }
    }
  }
`;

export const childrenAssetsByZevQuery = gql`
  query ChildrenAssetsByZev(
    $constraints: [AssetSearchConstraint]
    $childAssetSearchConstraints: [ChildAssetSearchConstraint]
    $relationTypeId: ID!
    $pagination: AssetPagination
  ) {
    assets(constraints: $constraints, pagination: $pagination) {
      ... on AssetsResult {
        totalNumberOfAssets
        assetsGroupedByAssetType {
          numberOfAssets
          assetType {
            id
            name
          }
          assets {
            id
            name
            type {
              id
              name
              outgoingRelations {
                id
                target {
                  id
                  name
                }
              }
            }
            attributeValues {
              id
              value
              attributeType {
                id
                name
                orderIndex
              }
            }
            children(constraints: $childAssetSearchConstraints, relationTypeId: $relationTypeId) {
              ... on AssetsResult {
                ...AssetChildrenDetails
              }
            }
          }
        }
      }
    }
  }

  ${assetChildrenDetailsFragment}
`;
