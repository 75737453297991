import { gql } from '@apollo/client';

export const importMultiAssetFileMutation = gql`
  mutation ImportMultiAssetFile($csvFile: Upload!, $timeRange: TimeRange!) {
    importMultiAssetFile(csvFile: $csvFile, timeRange: $timeRange) {
      ... on ImportResult {
        ok
      }
      ... on ImportBadRequestError {
        message
        badRequestErrorCode: errorCode
      }
      ... on NotFoundError {
        errorCode
      }
      ... on AccessDeniedError {
        errorCode
      }
      ... on ReadTimedOutError {
        errorCode
      }
    }
  }
`;
