import { gql } from '@apollo/client';

export const propertyAssetsQuery = gql`
  query PropertyAssets($constraints: [AssetSearchConstraint]) {
    assets(constraints: $constraints) {
      ... on AssetsResult {
        assetsGroupedByAssetType {
          numberOfAssets
          assets {
            id
            name
            type {
              id
            }
            attributeValues {
              id
              value
              attributeType {
                id
                name
                orderIndex
              }
            }
          }
        }
      }
    }
  }
`;
