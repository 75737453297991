import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AssetPagination,
  AssetSearchAssetTypeIdConstraint,
  AssetSearchConstraint,
  AssetSearchConstraintType,
  AssetSearchParentConstraint,
  ChildAssetSearchConstraint,
  ChildAssetSearchConstraintType,
} from '@teci/ecockpit-frontend-gateway-api';
import { EkaConfig } from '../../eka.types';
import { BalancingPointAssetModel } from '../../models/BalancingPointAsset.model';
import { EkaConfigModel } from '../../models/EkaConfig.model';
import { UserModel } from '../../models/User.model';
import {
  ChildrenAssetsByUtilizationUnit_assets_AssetsResult,
  ChildrenAssetsByUtilizationUnit_assets_SearchError,
  ChildrenAssetsByUtilizationUnitVariables,
  ChildrenAssetsByUtilizationUnit as ChildrenAssetsByUtilizationUnitQuery,
} from '../../queries/__generated__/ChildrenAssetsByUtilizationUnit';
import { childrenAssetsByUtilizationUnitQuery } from '../../queries/utilizationUnitAsset.query';

export type ChildrenAssetsByUtilizationUnitPayload = {
  config: EkaConfig;
  relationTypeId: string;
  pagination: AssetPagination;
  childrenAssetTypeId: string;
  utilizationUnitId: string;
  nameSubstring: string;
};

export type ChildrenAssetsByUtilizationUnitErrorCodes =
  ChildrenAssetsByUtilizationUnit_assets_SearchError['__typename'];

export type ChildrenAssetsByUtilizationUnitResponse =
  | ChildrenAssetsByUtilizationUnitErrorCodes
  | { childrenAssets: Array<BalancingPointAssetModel | UserModel>; totalNumberOfAssets: number };

export const prepareChildrenAssetsByUtilizationUnitVariables = (
  relationTypeId: string,
  pagination: AssetPagination,
  childrenAssetTypeId: string,
  utilizationUnitId: string,
  nameSubstring: string
): ChildrenAssetsByUtilizationUnitVariables => {
  const assetTypeIdConstraint: AssetSearchAssetTypeIdConstraint = {
    assetTypeId: childrenAssetTypeId,
    assetTypeIds: null,
  };

  const parentConstraint: AssetSearchParentConstraint = {
    parentId: utilizationUnitId,
    relationTypeId,
  };

  const constraints: AssetSearchConstraint[] = [
    {
      constraintType: AssetSearchConstraintType.ASSET_TYPE_ID,
      assetTypeIdConstraint,
      childrenAssetTypeIdConstraint: null,
      parentConstraint: null,
      assetSearchNameSubstringConstraint: null,
    },
    {
      constraintType: AssetSearchConstraintType.PARENT,
      childrenAssetTypeIdConstraint: null,
      assetTypeIdConstraint: null,
      parentConstraint,
      assetSearchNameSubstringConstraint: null,
    },
  ];

  if (nameSubstring) {
    constraints.push({
      constraintType: AssetSearchConstraintType.ASSET_SEARCH_SUBSTRING,
      assetTypeIdConstraint: null,
      childrenAssetTypeIdConstraint: null,
      parentConstraint: null,
      assetSearchNameSubstringConstraint: { substring: nameSubstring },
    });
  }

  const childAssetSearchConstraints: ChildAssetSearchConstraint[] = [
    {
      constraintType: ChildAssetSearchConstraintType.ASSET_TYPE_ID,
      assetTypeIdConstraint: {
        assetTypeId: childrenAssetTypeId,
        assetTypeIds: null,
      },
    },
  ];

  return { constraints, childAssetSearchConstraints, relationTypeId, pagination };
};

export const mapChildrenAssets: (
  { assetsGroupedByAssetType, totalNumberOfAssets }: ChildrenAssetsByUtilizationUnit_assets_AssetsResult,
  config: EkaConfig
) => { childrenAssets: Array<BalancingPointAssetModel | UserModel>; totalNumberOfAssets: number } = (
  { assetsGroupedByAssetType, totalNumberOfAssets }: ChildrenAssetsByUtilizationUnit_assets_AssetsResult,
  config: EkaConfig
) => {
  const _config = new EkaConfigModel(config);

  return {
    childrenAssets: assetsGroupedByAssetType.flatMap(({ assets }) =>
      assets
        .filter(({ children }) => children.__typename === 'AssetsResult')
        .map((asset) => {
          return _config.isBalancingPointAssetType(asset.type.id)
            ? new BalancingPointAssetModel(config).withData(asset)
            : new UserModel(config).withData(asset);
        })
    ),
    totalNumberOfAssets,
  };
};

export const fetchChildrenAssetsByUtilizationUnit = createAsyncThunk<
  | { childrenAssets: Array<BalancingPointAssetModel | UserModel>; totalNumberOfAssets: number }
  | ChildrenAssetsByUtilizationUnitErrorCodes,
  ChildrenAssetsByUtilizationUnitPayload
>('assets/fetchChildrenAssetsByUtilizationUnit', async (payload, { rejectWithValue }) => {
  const { relationTypeId, pagination, childrenAssetTypeId, utilizationUnitId, nameSubstring, config } = payload;

  try {
    const client = getApolloClient();

    // Prepare the query variables
    const variables: ChildrenAssetsByUtilizationUnitVariables = prepareChildrenAssetsByUtilizationUnitVariables(
      relationTypeId,
      pagination,
      childrenAssetTypeId,
      utilizationUnitId,
      nameSubstring
    );

    const { data } = await client.query<ChildrenAssetsByUtilizationUnitQuery>({
      query: childrenAssetsByUtilizationUnitQuery,
      variables,
      fetchPolicy: 'no-cache',
    });

    if (data?.assets?.__typename === 'AssetsResult') {
      return mapChildrenAssets(data.assets, config);
    } else {
      return data?.assets;
    }
  } catch (error) {
    return rejectWithValue({ message: error.message });
  }
});
