import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorCode } from '@teci/ecockpit-frontend-gateway-api';
import { EkaPremisesAndInstallationsCreationErrorCode } from '@typings/graphql-global';
import { createPremisesAndInstallationsMutation } from '../../queries/createPremisesAndInstallations.mutation';
import { getJobStatusQuery } from '@eka/queries/getJobStatus.query';
import { ekaActions } from '../eakSlice';

export type PremisesAndInstallationsCreationPayload = {
  zevId: string;
};

export type PremisesAndInstallationsCreationErrorCodes =
  | EkaPremisesAndInstallationsCreationErrorCode.JOB_IS_RUNNING
  | ErrorCode.NOT_FOUND
  | ErrorCode.NO_CREATION_PERMISSION
  | 'NotFoundError'
  | 'AccessDeniedError'
  | 'Error';

export const createPremisesAndInstallations = createAsyncThunk<
  boolean,
  PremisesAndInstallationsCreationPayload,
  { rejectValue: PremisesAndInstallationsCreationErrorCodes }
>('createPremisesAndInstallations/create', async ({ zevId }, { rejectWithValue, dispatch }) => {
  const apolloClient = getApolloClient();

  const jobStatusRefetchQuery = {
    variables: { zevId },
    query: getJobStatusQuery,
  };

  try {
    const { data } = await apolloClient.mutate({
      mutation: createPremisesAndInstallationsMutation,
      variables: { zevId },
      fetchPolicy: 'no-cache',
      refetchQueries: [jobStatusRefetchQuery],
      awaitRefetchQueries: true,
    });

    if (data?.ekaCreatePremisesAndInstallations.__typename === 'RequestSubmitted') {
      return data.ekaCreatePremisesAndInstallations.ok;
    } else if (data?.ekaCreatePremisesAndInstallations.__typename !== 'RequestSubmitted') {
      return rejectWithValue(
        data.ekaCreatePremisesAndInstallations.errorCode as PremisesAndInstallationsCreationErrorCodes
      );
    } else {
      return rejectWithValue('Error');
    }
  } catch (error) {
    return rejectWithValue('Error');
  } finally {
    setTimeout(() => dispatch(ekaActions.resetPremisesAndInstallations()), 1000);
  }
});
