import { gql } from '@apollo/client';

export const createInstallationMutation = gql`
  mutation CreateInstallation($balancingPointId: ID!) {
    ekaCreateInstallation(balancingPointId: $balancingPointId) {
      __typename
      ... on Installation {
        installationId
        timestamp
      }
      ... on EkaInstallationCreationError {
        errors {
          errorCode
          message
        }
      }
      ... on NotFoundError {
        __typename
        errorCode
      }
      ... on AccessDeniedError {
        __typename
        errorCode
      }
    }
  }
`;
