import { gql } from '@apollo/client';

export const createPremisesAndInstallationsMutation = gql`
  mutation CreatePremisesAndInstallations($zevId: ID!) {
    ekaCreatePremisesAndInstallations(zevId: $zevId) {
      __typename
      ... on RequestSubmitted {
        ok
      }
      ... on EkaPremisesAndInstallationsCreationError {
        errors {
          errorCode
          message
        }
      }
      ... on AccessDeniedError {
        errorCode
      }
      ... on NotFoundError {
        errorCode
      }
    }
  }
`;
