import { gql } from '@apollo/client';

export const updateUtilizationUnitAssetMutation = gql`
  mutation UpdateUtilizationUnitAsset($utilizationUnitAssetId: ID!, $name: String!, $originalSapPremiseId: String) {
    ekaUpdateUtilizationUnit(
      utilizationUnitId: $utilizationUnitAssetId
      name: $name
      originalSapPremiseId: $originalSapPremiseId
    ) {
      ... on Asset {
        id
        name
        attributeValues {
          id
          value
          attributeType {
            id
            name
          }
        }
      }
      ... on EkaUtilizationUnitUpdateError {
        errors {
          __typename
          errorCode
          message
        }
      }
      ... on AccessDeniedError {
        __typename
        errorCode
      }
      ... on NotFoundError {
        __typename
        errorCode
      }
    }
  }
`;
