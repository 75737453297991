import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AssetPagination,
  AssetSearchAssetTypeIdConstraint,
  AssetSearchChildrenAssetTypeIdConstraint,
  AssetSearchConstraint,
  AssetSearchConstraintType,
  ChildAssetSearchConstraint,
  ChildAssetSearchConstraintType,
} from '@teci/ecockpit-frontend-gateway-api';
import { EkaConfig } from '../../eka.types';
import { PropertyAssetModel } from '../../models/PropertyAsset.model';
import {
  ZevAssetsByProperty_assets_AssetsResult as ZevAssetsByProperty,
  ZevAssetsByProperty_assets_AssetsResult,
  ZevAssetsByPropertyVariables,
} from '../../queries/__generated__/ZevAssetsByProperty';
import { zevAssetsByPropertyQuery } from '../../queries/zevAssetsByProperty.query';

export type PropertyAssetsWithZevAssetsPayload = {
  config: EkaConfig;
  relationTypeId: string;
  pagination: AssetPagination;
  assetName: string;
};

export type PropertyAssetsWithZevAssetsResponse = {
  propertiesWithZevAssets: PropertyAssetModel[];
  totalNumberOfAssets: number;
};

export const mapZevAssets: (
  { assetsGroupedByAssetType, totalNumberOfAssets }: ZevAssetsByProperty_assets_AssetsResult,
  config: EkaConfig
) => { propertiesWithZevAssets: PropertyAssetModel[]; totalNumberOfAssets: number } = (
  { assetsGroupedByAssetType, totalNumberOfAssets }: ZevAssetsByProperty,
  config: EkaConfig
) => {
  return {
    propertiesWithZevAssets: assetsGroupedByAssetType.flatMap(({ assets }) =>
      assets
        .filter(({ children }) => children.__typename === 'AssetsResult')
        .map((asset) => new PropertyAssetModel(config).withData(asset))
    ),
    totalNumberOfAssets,
  };
};

export const prepareZevAssetsByPropertyVariables = (
  config: EkaConfig,
  relationTypeId: string,
  pagination: AssetPagination,
  assetName: string | null
): ZevAssetsByPropertyVariables => {
  const assetTypeIdConstraint: AssetSearchAssetTypeIdConstraint = {
    assetTypeId: config.property.assetTypeId,
    assetTypeIds: null,
  };

  const childrenAssetTypeIdConstraint: AssetSearchChildrenAssetTypeIdConstraint = {
    assetTypeId: config.zev.assetTypeId,
    relationTypeId,
  };

  const constraints: AssetSearchConstraint[] = [
    {
      constraintType: AssetSearchConstraintType.ASSET_TYPE_ID,
      assetTypeIdConstraint,
      childrenAssetTypeIdConstraint: null,
      parentConstraint: null,
      assetSearchNameSubstringConstraint: null,
    },
    {
      constraintType: AssetSearchConstraintType.CHILDREN_ASSET_TYPE_ID,
      childrenAssetTypeIdConstraint,
      assetTypeIdConstraint: null,
      parentConstraint: null,
      assetSearchNameSubstringConstraint: null,
    },
  ];

  if (assetName) {
    constraints.push({
      constraintType: AssetSearchConstraintType.ASSET_SEARCH_SUBSTRING,
      assetTypeIdConstraint: null,
      childrenAssetTypeIdConstraint: null,
      parentConstraint: null,
      assetSearchNameSubstringConstraint: { substring: assetName },
    });
  }

  const childAssetSearchConstraints: ChildAssetSearchConstraint[] = [
    {
      constraintType: ChildAssetSearchConstraintType.ASSET_TYPE_ID,
      assetTypeIdConstraint: {
        assetTypeId: config.zev.assetTypeId,
        assetTypeIds: null,
      },
    },
  ];

  return { constraints, childAssetSearchConstraints, relationTypeId, pagination };
};

export const fetchPropertyAssetsWithZevAssets = createAsyncThunk<
  PropertyAssetsWithZevAssetsResponse,
  PropertyAssetsWithZevAssetsPayload,
  { rejectValue: string }
>(
  'propertyAssets/fetchWithZevAssets',
  async ({ config, relationTypeId, pagination, assetName }, { rejectWithValue }) => {
    try {
      const apolloClient = getApolloClient();
      const variables = prepareZevAssetsByPropertyVariables(config, relationTypeId, pagination, assetName);

      const { data } = await apolloClient.query({
        query: zevAssetsByPropertyQuery,
        variables,
        fetchPolicy: 'cache-first',
      });

      if (data.assets.__typename === 'AssetsResult') {
        return {
          propertiesWithZevAssets: mapZevAssets(data.assets, config).propertiesWithZevAssets,
          totalNumberOfAssets: mapZevAssets(data.assets, config).totalNumberOfAssets,
        };
      } else {
        return rejectWithValue('Error: AssetsResult not found');
      }
    } catch (error) {
      return rejectWithValue('Failed to fetch property assets with ZEV assets');
    }
  }
);
