import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorCode } from '@typings/graphql-global';
import { ExtractErrorCodesFromCommonApiCreateUpdateQuery } from '@utils/util.types';
import {
  UpdateAssignedUser_ekaUpdateUser,
  UpdateAssignedUser_ekaUpdateUser_Asset,
} from '../../queries/__generated__/UpdateAssignedUser';
import { isValid } from 'date-fns';
import { updateAssignedUserMutation } from '../../queries/updateAssignedUser.mutation';

export type AssignedUserUpdatePayload = {
  utilizationUnitId: string;
  userAssetId: string;
  start?: Date;
  end?: Date;
  relationTypeId?: string;
};

export type AssignedUserUpdateErrorCodes =
  | ExtractErrorCodesFromCommonApiCreateUpdateQuery<UpdateAssignedUser_ekaUpdateUser>
  | ErrorCode.NO_UPDATE_PERMISSION
  | ErrorCode.NOT_FOUND
  | 'NotFoundError'
  | 'AccessDeniedError'
  | 'Error';

export type AssignedUserUpdateResponse =
  | AssignedUserUpdateErrorCodes
  | AssignedUserUpdateErrorCodes[]
  | UpdateAssignedUser_ekaUpdateUser_Asset;

export const updateAssignedUser = createAsyncThunk<
  AssignedUserUpdateResponse | null,
  AssignedUserUpdatePayload,
  { rejectValue: AssignedUserUpdateErrorCodes }
>(
  'assignedUser/update',
  async ({ utilizationUnitId, userAssetId, start, end, relationTypeId }, { rejectWithValue }) => {
    const apolloClient = getApolloClient();

    const from = start && isValid(start) ? start.toJSON() : undefined;
    const until = end && isValid(end) ? end.toJSON() : undefined;

    try {
      const { data } = await apolloClient.mutate({
        mutation: updateAssignedUserMutation,
        variables: {
          utilizationUnitId,
          userAssetId,
          update: {
            moveIn: from,
            moveOut: until,
          },
        },
        fetchPolicy: 'no-cache', // Use appropriate fetch policy
      });

      // Ensure there is data in the response
      if (!data) {
        throw new Error('No data in response');
      }

      if (data.ekaUpdateUser.__typename === 'Asset') {
        return data.ekaUpdateUser;
      } else if (data.ekaUpdateUser.errors?.length) {
        return rejectWithValue(data.ekaUpdateUser.errors);
      } else {
        return rejectWithValue(data.ekaUpdateUser);
      }
    } catch (error) {
      return rejectWithValue('Error');
    }
  }
);
