import { gql } from '@apollo/client';

export const generateBalancingPointDesignationMutation = gql`
  mutation GenerateBalancingPointDesignation($propertyId: ID!) {
    ekaGenerateBalancingPointDesignation(propertyId: $propertyId) {
      __typename
      ... on EkaGenerateBalancingPointDesignationSuccess {
        balancingPointDesignation
      }
      ... on NotFoundError {
        __typename
        errorCode
      }
      ... on AccessDeniedError {
        __typename
        errorCode
      }
      ... on EkaGenerateBalancingPointDesignationError {
        errors {
          errorCode
          message
        }
      }
    }
  }
`;
