import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { EkaBalancingPointUpdate } from '@teci/ecockpit-frontend-gateway-api';
import { ErrorCode } from '@typings/graphql-global';
import { ExtractErrorCodesFromCommonApiCreateUpdateQuery } from '@utils/util.types';
import {
  UpdateBalancingPointAsset,
  UpdateBalancingPointAsset_ekaUpdateBalancingPoint,
  UpdateBalancingPointAsset_ekaUpdateBalancingPoint_Asset,
  UpdateBalancingPointAssetVariables,
} from '../../queries/__generated__/UpdateBalancingPointAsset';
import { updateBalancingPointAssetMutation } from '../../queries/updateBalancingPointAsset.mutation';

export type BalancingPointAssetUpdatePayload = {
  balancingPointId: string;
  update: EkaBalancingPointUpdate;
};

export type BalancingPointAssetUpdateErrorCodes =
  | ExtractErrorCodesFromCommonApiCreateUpdateQuery<UpdateBalancingPointAsset_ekaUpdateBalancingPoint>
  | ErrorCode.NOT_FOUND
  | ErrorCode.NO_CREATION_PERMISSION
  | 'AccessDeniedError'
  | 'NotFoundError'
  | 'Error';

export type BalancingPointAssetUpdateResponse =
  | BalancingPointAssetUpdateErrorCodes
  | UpdateBalancingPointAsset_ekaUpdateBalancingPoint_Asset;

export const updateBalancingPointAsset = createAsyncThunk<
  BalancingPointAssetUpdateResponse,
  BalancingPointAssetUpdatePayload,
  { rejectValue: BalancingPointAssetUpdateErrorCodes }
>('balancingPointAsset/update', async (payload, { rejectWithValue }) => {
  const apolloClient = getApolloClient();

  try {
    const { data } = await apolloClient.mutate<UpdateBalancingPointAsset, UpdateBalancingPointAssetVariables>({
      mutation: updateBalancingPointAssetMutation,
      variables: {
        balancingPointId: payload.balancingPointId,
        update: payload.update,
      },
      fetchPolicy: 'no-cache',
    });

    if (data?.ekaUpdateBalancingPoint.__typename === 'Asset') {
      return data.ekaUpdateBalancingPoint;
    } else {
      return rejectWithValue(data.ekaUpdateBalancingPoint as unknown as BalancingPointAssetUpdateErrorCodes);
    }
  } catch (error) {
    return rejectWithValue('Error');
  }
});
