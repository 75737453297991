import { Model } from '@utils/model.utils';
import { Expose } from 'class-transformer';
import { EkaConfigBalancingPoint } from '../eka.types';
import { ChildrenAssetsByZev_assets_AssetsResult_assetsGroupedByAssetType_assets as ZevBalancingPointAssetSchema } from '../queries/__generated__/ChildrenAssetsByZev';
import { ChildrenAssetsByUtilizationUnit_assets_AssetsResult_assetsGroupedByAssetType_assets as UtilizationUnitBalancingPointAssetSchema } from '../queries/__generated__/ChildrenAssetsByUtilizationUnit';
import { com } from '@teci/ecockpit-features-api';

interface BalancingPointAssetModelConfigInterface {
  measurementData:
    | com.kiwigrid.teci.ecockpit.features.api.config.eka.BalancingPointPowerGridConsumption.MeasurementData
    | com.kiwigrid.teci.ecockpit.features.api.config.eka.BalancingPointConsumption.MeasurementData
    | com.kiwigrid.teci.ecockpit.features.api.config.eka.BalancingPointProduction.MeasurementData;
}
export class BalancingPointAssetModel extends Model.fromSchema<
  ZevBalancingPointAssetSchema | UtilizationUnitBalancingPointAssetSchema
>() {
  private _attributes?: Map<string, string>;
  private _balancingPointConfig: EkaConfigBalancingPoint | undefined;

  @Expose()
  get id(): string {
    return this._raw.id;
  }

  @Expose()
  get name(): string {
    return this._raw.name;
  }

  @Expose()
  get type(): string {
    return this._raw.type.id;
  }

  @Expose()
  get typeName(): string {
    return this._raw.type.name;
  }

  @Expose()
  get startDate(): Date | undefined {
    if (this._balancingPointConfig === undefined) return undefined;
    const date = this._attributes?.get(this._balancingPointConfig.balancingPeriod.startDateAttributeTypeId);

    return !date ? undefined : new Date(date);
  }
  @Expose()
  get endDate(): Date | undefined {
    if (this._balancingPointConfig === undefined) return undefined;
    const date = this._attributes?.get(this._balancingPointConfig.balancingPeriod.endDateAttributeTypeId);
    return !date ? undefined : new Date(date);
  }
  @Expose()
  get typeDesignation(): string {
    return this._config.getBalancingPointConfigKeyByAssetTypeId(this.type);
  }

  @Expose()
  get sapInstallation(): string | undefined {
    if (
      this._balancingPointConfig === undefined ||
      !this._config.isBalancingPointWithIntegrationProperties(this._balancingPointConfig)
    )
      return undefined;

    return this._attributes?.get(this._balancingPointConfig.sapIntegration.installationAttributeTypeId);
  }

  @Expose()
  get deviceId(): string | undefined {
    const type = this._config.getBalancingPointConfigKeyByAssetTypeId(this.type);
    const balancingPointAssetModelConfig = this._balancingPointConfig as BalancingPointAssetModelConfigInterface;

    if (type === 'production' || type === 'consumption' || type === 'powerGridConsumption') {
      return this._attributes?.get(balancingPointAssetModelConfig.measurementData.powerInAttributeTypeId);
    }
    return undefined;
  }

  @Expose()
  get serialNumber(): string | undefined {
    const type = this._config.getBalancingPointConfigKeyByAssetTypeId(this.type);
    const balancingPointAssetModelConfig = this._balancingPointConfig as BalancingPointAssetModelConfigInterface;

    if (type === 'production' || type === 'consumption' || type === 'powerGridConsumption') {
      return this._attributes?.get(balancingPointAssetModelConfig.measurementData.serialNumberAttributeTypeId);
    }
    return undefined;
  }

  @Expose()
  get manufacturer(): string | undefined {
    const type = this._config.getBalancingPointConfigKeyByAssetTypeId(this.type);
    const balancingPointAssetModelConfig = this._balancingPointConfig as BalancingPointAssetModelConfigInterface;

    if (type === 'production' || type === 'consumption' || type === 'powerGridConsumption') {
      return this._attributes?.get(balancingPointAssetModelConfig.measurementData.manufacturerAttributeTypeId);
    }
    return undefined;
  }

  @Expose()
  get meteringPointDesignation(): string | undefined {
    if (this._balancingPointConfig === undefined) {
      return undefined;
    }

    return this._attributes?.get(this._balancingPointConfig.meteringPoint.designationAttributeTypeId);
  }

  @Expose()
  get sapPointOfDelivery(): string | undefined {
    if (
      this._balancingPointConfig === undefined ||
      !this._config.isBalancingPointWithIntegrationProperties(this._balancingPointConfig)
    )
      return undefined;
    return this._attributes?.get(this._balancingPointConfig.sapIntegration.pointOfDeliveryAttributeTypeId);
  }

  @Expose()
  get timestamp(): Date | undefined {
    if (
      this._balancingPointConfig === undefined ||
      !this._config.isBalancingPointWithIntegrationProperties(this._balancingPointConfig)
    )
      return undefined;
    const date = this._attributes?.get(this._balancingPointConfig.sapIntegration.updateAttributeTypeId);
    return !date ? undefined : new Date(date);
  }

  withData(data: ZevBalancingPointAssetSchema | UtilizationUnitBalancingPointAssetSchema): this {
    // retrieve specific config once
    this._balancingPointConfig = this._config.getBalancingPointConfigByAssetTypeId(data.type.id);
    // prepare the attributes map once initially
    this._attributes = new Map(data.attributeValues.map(({ attributeType, value }) => [attributeType.id, value]));
    // call parent method which will return the locked instance
    return super.withData(data);
  }
}
