import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { relationTypesQuery } from '@components/RelationTypes/relationTypes.query';
import { RelationTypes } from '@components/RelationTypes/relationTypes.types';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchRelationTypes = createAsyncThunk<RelationTypes>(
  'relationTypes/fetchRelationTypes',
  async (_, { rejectWithValue }) => {
    try {
      const apolloClient = getApolloClient();
      const { data } = await apolloClient.query<RelationTypes>({
        query: relationTypesQuery,
        fetchPolicy: 'cache-first',
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
