import { gql } from '@apollo/client';

export const createAssignedUserMutation = gql`
  mutation CreateAssignedUser($utilizationUnitId: ID!, $creation: EkaUserCreation!) {
    ekaCreateUser(utilizationUnitId: $utilizationUnitId, creation: $creation) {
      ... on Asset {
        id
        name
        attributeValues {
          id
          value
          attributeType {
            id
            name
          }
        }
      }
      ... on EkaUserCreationError {
        errors {
          __typename
          errorCode
          message
        }
      }
      ... on AccessDeniedError {
        __typename
        errorCode
      }
    }
  }
`;
