import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { EkaPremiseUpdateErrorCode, EkaUtilizationUnitUpdateErrorCode, ErrorCode } from '@typings/graphql-global';
import { ExtractErrorCodesFromCommonApiCreateUpdateQuery } from '@utils/util.types';
import {
  UpdateUtilizationUnitAsset,
  UpdateUtilizationUnitAsset_ekaUpdateUtilizationUnit,
  UpdateUtilizationUnitAsset_ekaUpdateUtilizationUnit_Asset,
  UpdateUtilizationUnitAssetVariables,
} from '../../queries/__generated__/UpdateUtilizationUnitAsset';
import { updateUtilizationUnitAssetMutation } from '../../queries/updateUtilizationUnitAsset.mutation';

export type UtilizationUnitAssetUpdatePayload = {
  id: string;
  name: string;
  originalSapPremiseId: string;
};

export type UtilizationUnitUpdateErrorCodes =
  | EkaPremiseUpdateErrorCode
  | EkaUtilizationUnitUpdateErrorCode
  | ExtractErrorCodesFromCommonApiCreateUpdateQuery<UpdateUtilizationUnitAsset_ekaUpdateUtilizationUnit>
  | ErrorCode.NOT_FOUND
  | ErrorCode.NO_UPDATE_PERMISSION
  | 'Error'
  | 'EkaPremiseUpdateError';

export type UtilizationUnitAssetUpdateResponse =
  | UtilizationUnitUpdateErrorCodes
  | UpdateUtilizationUnitAsset_ekaUpdateUtilizationUnit_Asset;

export const updateUtilizationUnitAsset = createAsyncThunk<
  UtilizationUnitAssetUpdateResponse,
  UtilizationUnitAssetUpdatePayload,
  { rejectValue: UtilizationUnitUpdateErrorCodes }
>(
  'utilizationUnitAsset/update',
  async (
    { id: utilizationUnitAssetId, name, originalSapPremiseId }: UtilizationUnitAssetUpdatePayload,
    { rejectWithValue }
  ) => {
    const apolloClient = getApolloClient();

    try {
      // Make the mutation request
      const { data } = await apolloClient.mutate<UpdateUtilizationUnitAsset, UpdateUtilizationUnitAssetVariables>({
        mutation: updateUtilizationUnitAssetMutation,
        variables: { utilizationUnitAssetId, name, originalSapPremiseId },
        fetchPolicy: 'no-cache',
      });

      // Check if the response is successful
      if (data && data.ekaUpdateUtilizationUnit.__typename === 'Asset') {
        return data.ekaUpdateUtilizationUnit;
      } else {
        // If it's not successful, handle it as a failure
        return rejectWithValue(
          (data?.ekaUpdateUtilizationUnit['__typename'] as UtilizationUnitUpdateErrorCodes) ?? 'Error'
        );
      }
    } catch (error) {
      return rejectWithValue('Error');
    }
  }
);
