import {
  BalancingPointAssetDeleteErrorCodes,
  deleteBalancingPointAsset,
} from '@eka/redux/thunks/deleteBalancingPointAsset';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UtilizationUnitAsset } from '../eka.types';
import { BalancingPointAssetModel } from '../models/BalancingPointAsset.model';
import { PropertyAssetModel } from '../models/PropertyAsset.model';
import { UtilizationUnitAssetModel } from '../models/UtilizationUnitAsset.model';
import { ZevAssetModel } from '../models/ZevAsset.model';
import { AmendSapPremiseIds_ekaAmendSapPremiseIds } from '../queries/__generated__/AmendSapPremiseIds';
import { CreateAssignedUser_ekaCreateUser_Asset } from '../queries/__generated__/CreateAssignedUser';
import { CreateBalancingPointAsset_ekaCreateBalancingPoint_Asset } from '../queries/__generated__/CreateBalancingPointAsset';
import { CreateInstallation_ekaCreateInstallation } from '../queries/__generated__/CreateInstallation';
import { CreatePremise_ekaCreatePremise_Premise } from '../queries/__generated__/CreatePremise';
import { CreateUtilizationUnitAsset_ekaCreateUtilizationUnit_Asset } from '../queries/__generated__/CreateUtilizationUnitAsset';
import { CreateZevAsset } from '../queries/__generated__/CreateZevAsset';
import { ExportZevBillingData_ekaExportZevBillingData } from '../queries/__generated__/ExportZevBillingData';
import { GenerateBalancingPointDesignation_ekaGenerateBalancingPointDesignation_EkaGenerateBalancingPointDesignationSuccess } from '../queries/__generated__/GenerateBalancingPointDesignation';
import { ImportMultiAssetFile_importMultiAssetFile_ImportResult } from '../queries/__generated__/ImportMultiAssetFile';
import { UpdateAssignedUser_ekaUpdateUser_Asset } from '../queries/__generated__/UpdateAssignedUser';
import { UpdateBalancingPointAsset_ekaUpdateBalancingPoint_Asset } from '../queries/__generated__/UpdateBalancingPointAsset';
import { UpdatePremise_ekaUpdatePremise_OkResult } from '../queries/__generated__/UpdatePremise';
import { UpdateUtilizationUnitAsset_ekaUpdateUtilizationUnit_Asset } from '../queries/__generated__/UpdateUtilizationUnitAsset';
import { UpdateZevAsset_ekaUpdateZev_Asset } from '../queries/__generated__/UpdateZevAsset';
import { createInstallation, InstallationCreateErrorCodes } from './thunks/createInstallation';
import { createAssignedUser } from './thunks/createAssignedUser';
import { BalancingPointAssetCreateErrorCodes, createBalancingPointAsset } from './thunks/createBalancingPointAsset';
import { createEkaPremise, EkaPremiseCreateErrorCodes } from './thunks/createEkaPremise';
import {
  createPremisesAndInstallations,
  PremisesAndInstallationsCreationErrorCodes,
} from './thunks/createPremisesAndInstallations';
import { createUtilizationUnitAsset, UtilizationUnitAssetCreateErrorCodes } from './thunks/createUtilizationUnitAsset';
import { createZevAsset, ZevAssetCreateErrorCodes } from './thunks/createZevAsset';
import { AssignedUserDeleteErrorCodes, deleteAssignedUser } from './thunks/deleteAssignedUser';
import { deleteUtilizationUnitAsset, UtilizationUnitAssetDeleteErrorCodes } from './thunks/deleteUtilizationUnitAsset';
import { exportZevBillingData, ExportZevBillingDataErrorCodes } from './thunks/exportBillingData';
import { exportSapPremiseIds, ExportSapPremiseIdsErrorCodes } from './thunks/exportSapPremiseIds';
import {
  EkaExportZevTimeSeriesResponse,
  exportZevTimeSeries,
  ExportZevTimeSeriesErrorCodes,
} from './thunks/exportZevTimeSeries';
import {
  ChildrenAssetsByUtilizationUnitErrorCodes,
  ChildrenAssetsByUtilizationUnitResponse,
  fetchChildrenAssetsByUtilizationUnit,
} from './thunks/fetchChildrenAssetsByUtilizationUnit';
import { fetchChildrenAssetsByZev } from './thunks/fetchChildrenAssetsByZev';
import { fetchPropertyAsset } from './thunks/fetchPropertyAsset';
import { fetchPropertyAssets } from './thunks/fetchPropertyAssets';
import {
  fetchPropertyAssetsWithZevAssets,
  PropertyAssetsWithZevAssetsResponse,
} from './thunks/fetchPropertyAssetsWithZevAssets';
import { fetchUtilizationUnitAsset, UtilizationUnitAssetErrorCodes } from './thunks/fetchUtilizationUnitAsset';
import { fetchZevAsset } from './thunks/fetchZevAsset';
import {
  BalancingPointDesignationErrorCodes,
  generateBalancingPointDesignation,
} from './thunks/generateBalancingPointDesignation';
import { importMultiAssetFile, ImportMultiAssetFileErrorCodes } from './thunks/importMultiAssetFile';
import { importUtilizationUnits, UtilizationUnitsImportErrorCodes } from './thunks/importUtilizationUnits';
import { AssignedUserUpdateErrorCodes, updateAssignedUser } from './thunks/updateAssignedUser';
import { BalancingPointAssetUpdateErrorCodes, updateBalancingPointAsset } from './thunks/updateBalancingPointAsset';
import { EkaPremiseUpdateErrorCodes, updateEkaPremise } from './thunks/updateEkaPremise';
import { EkaInstallationUpdateErrorCodes, updateInstallation } from './thunks/updateInstallation';
import { updateUtilizationUnitAsset, UtilizationUnitUpdateErrorCodes } from './thunks/updateUtilizationUnitAsset';
import { updateZevAsset, ZevUpdateErrorCodes } from './thunks/updateZevAsset';

interface EkaState {
  anchorAssetId?: string;
  allPropertyAssets: PropertyAssetModel[];
  ekaPagination: {
    pageNumber: number;
    itemsPerPage: number;
  };
  lastEkaItemPerPage: {
    page: number;
    anchorAssetId: string | null;
  }[];
  propertyAssetsWithZevAssets: {
    propertiesWithZevAssets: PropertyAssetModel[];
    totalNumberOfAssets: number;
  };
  loadingWithZev: boolean;
  ekaExportZev: {
    ekaExportZevTimeSeries?: EkaExportZevTimeSeriesResponse;
    exportZevTimeSeriesError?: ExportZevTimeSeriesErrorCodes;
  };
  premisesAndInstallations: {
    result: boolean;
    loading: boolean;
    error?: PremisesAndInstallationsCreationErrorCodes;
  };
  createZevAsset: {
    result?: CreateZevAsset;
    error?: ZevAssetCreateErrorCodes;
    loading?: boolean;
  };
  updateZevAsset: {
    result?: UpdateZevAsset_ekaUpdateZev_Asset;
    error?: ZevUpdateErrorCodes;
  };
  exportBillingData: {
    result?: ExportZevBillingData_ekaExportZevBillingData;
    error?: ExportZevBillingDataErrorCodes;
    loading: boolean;
  };
  readTimedOutMessage: {
    errorCode?: string;
    message?: string;
  };
  importMultiAsset: {
    result?: ImportMultiAssetFile_importMultiAssetFile_ImportResult;
    error?: ImportMultiAssetFileErrorCodes;
    loading: boolean;
  };
  // ZevAssetDetails
  zevAsset?: ZevAssetModel;
  utilizationUnitsConsumption: {
    childrenAssets?: (BalancingPointAssetModel | UtilizationUnitAssetModel)[];
    totalNumberOfAssets: number;
  };
  utilizationUnitsConsumptionLoading: boolean;
  utilizationUnitsOwner: {
    childrenAssets?: (BalancingPointAssetModel | UtilizationUnitAssetModel)[];
    totalNumberOfAssets: number;
  };
  utilizationUnitsOwnerLoading: boolean;
  balancingPointsPowerGrid: {
    childrenAssets?: (BalancingPointAssetModel | UtilizationUnitAssetModel)[];
    totalNumberOfAssets: number;
  };
  balancingPointsPowerGridLoading: boolean;
  balancingPointsProduction: {
    childrenAssets?: (BalancingPointAssetModel | UtilizationUnitAssetModel)[];
    totalNumberOfAssets: number;
  };
  balancingPointsProductionLoading: boolean;
  propertyAsset?: PropertyAssetModel;
  balancingPointVisibleModal: boolean;
  createBalancingPointAsset: {
    result?: CreateBalancingPointAsset_ekaCreateBalancingPoint_Asset;
    error?: BalancingPointAssetCreateErrorCodes;
    loading: boolean;
  };
  updateBalancingPointAsset: {
    result?: UpdateBalancingPointAsset_ekaUpdateBalancingPoint_Asset;
    error?: BalancingPointAssetUpdateErrorCodes;
    loading: boolean;
  };
  deleteBalancingPointAsset: {
    result: boolean;
    error?: BalancingPointAssetDeleteErrorCodes;
  };
  // UU
  utilizationUnitVisibleModal: boolean;
  createUtilizationUnitAsset: {
    result?: CreateUtilizationUnitAsset_ekaCreateUtilizationUnit_Asset;
    error?: UtilizationUnitAssetCreateErrorCodes;
    loading: boolean;
  };
  updateUtilizationUnitAsset: {
    result?: UpdateUtilizationUnitAsset_ekaUpdateUtilizationUnit_Asset;
    error?: UtilizationUnitUpdateErrorCodes;
    loading: boolean;
  };
  deleteUtilizationUnitAsset: {
    result: boolean;
    error?: UtilizationUnitAssetDeleteErrorCodes;
  };
  createEkaPremise: {
    result?: CreatePremise_ekaCreatePremise_Premise;
    error?: EkaPremiseCreateErrorCodes;
    loading: boolean;
  };
  updateEkaPremise: {
    result?: UpdatePremise_ekaUpdatePremise_OkResult;
    error?: EkaPremiseUpdateErrorCodes;
    loading: boolean;
  };
  lastUuConsumptionItemPerPage: { page: number; anchorAssetId?: string }[];
  lastUuOwnerItemPerPage: { page: number; anchorAssetId?: string }[];
  exportSapPremiseIds: { result?: AmendSapPremiseIds_ekaAmendSapPremiseIds; error?: ExportSapPremiseIdsErrorCodes[] };
  exportDialogVisible: boolean;
  importUtilizationUnits: { result: boolean; error?: UtilizationUnitsImportErrorCodes };
  importDialogVisible: boolean;
  uuAssets: {
    result?: UtilizationUnitAsset;
    error?: UtilizationUnitAssetErrorCodes;
  };
  childrenAssetsByUU: {
    result?: ChildrenAssetsByUtilizationUnitResponse;
    error?: ChildrenAssetsByUtilizationUnitErrorCodes;
    loading: boolean;
  };
  installationCreate: {
    result?: CreateInstallation_ekaCreateInstallation;
    error?: InstallationCreateErrorCodes | InstallationCreateErrorCodes[];
    loading: boolean;
  };
  installationUpdate: {
    result?: boolean;
    error?: EkaInstallationUpdateErrorCodes;
    loading: boolean;
  };
  BPDesignation: {
    result?: GenerateBalancingPointDesignation_ekaGenerateBalancingPointDesignation_EkaGenerateBalancingPointDesignationSuccess;
    error?: BalancingPointDesignationErrorCodes;
    loading: boolean;
  };
  createAssignedUser: {
    result?: CreateAssignedUser_ekaCreateUser_Asset;
    error?: AssignedUserUpdateErrorCodes | AssignedUserUpdateErrorCodes[];
    loading: boolean;
  };
  visibleModalAssignedUser: boolean;
  updateAssignedUser: {
    result?: UpdateAssignedUser_ekaUpdateUser_Asset;
    error?: AssignedUserUpdateErrorCodes | AssignedUserUpdateErrorCodes[];
    loading: boolean;
  };
  deleteAssignedUser: { result: boolean; error?: AssignedUserDeleteErrorCodes | AssignedUserDeleteErrorCodes[] };
  lastAssignedUserItemPerPage: { page: number; anchorAssetId?: string };
  lastBpProductionItemPerPage: { page: number; anchorAssetId?: string };
  lastBpGridItemPerPage: { page: number; anchorAssetId?: string };
  lastUuBpItemPerPage: { page: number; anchorAssetId?: string };
}

const initialState: EkaState = {
  anchorAssetId: null,
  allPropertyAssets: [],
  ekaPagination: {
    pageNumber: 0,
    itemsPerPage: 10,
  },
  lastEkaItemPerPage: [
    {
      page: 0,
      anchorAssetId: undefined,
    },
  ],
  propertyAssetsWithZevAssets: {
    propertiesWithZevAssets: [],
    totalNumberOfAssets: 0,
  },
  loadingWithZev: false,
  ekaExportZev: {
    ekaExportZevTimeSeries: undefined,
    exportZevTimeSeriesError: undefined,
  },
  premisesAndInstallations: {
    result: false,
    loading: false,
    error: null,
  },
  createZevAsset: {
    result: undefined,
    error: undefined,
    loading: false,
  },
  updateZevAsset: {
    result: undefined,
    error: undefined,
  },
  exportBillingData: {
    result: undefined,
    error: undefined,
    loading: false,
  },
  readTimedOutMessage: {
    errorCode: undefined,
    message: undefined,
  },
  importMultiAsset: {
    result: undefined,
    error: undefined,
    loading: false,
  },
  zevAsset: undefined,
  utilizationUnitsConsumption: { childrenAssets: undefined, totalNumberOfAssets: 0 },
  utilizationUnitsConsumptionLoading: false,
  utilizationUnitsOwner: { childrenAssets: undefined, totalNumberOfAssets: 0 },
  utilizationUnitsOwnerLoading: false,
  balancingPointsPowerGrid: { childrenAssets: undefined, totalNumberOfAssets: 0 },
  balancingPointsPowerGridLoading: false,
  balancingPointsProduction: { childrenAssets: undefined, totalNumberOfAssets: 0 },
  balancingPointsProductionLoading: false,
  propertyAsset: undefined,
  // BP
  balancingPointVisibleModal: false,
  createBalancingPointAsset: { result: undefined, error: undefined, loading: undefined },
  updateBalancingPointAsset: { result: undefined, error: undefined, loading: undefined },
  deleteBalancingPointAsset: { result: false, error: undefined },
  // UU
  utilizationUnitVisibleModal: false,
  createUtilizationUnitAsset: { result: undefined, error: undefined, loading: false },
  updateUtilizationUnitAsset: { result: undefined, error: undefined, loading: false },
  deleteUtilizationUnitAsset: { result: false, error: undefined },
  createEkaPremise: { result: undefined, error: undefined, loading: false },
  updateEkaPremise: { result: undefined, error: undefined, loading: false },
  lastUuConsumptionItemPerPage: [{ page: 0, anchorAssetId: undefined }],
  lastUuOwnerItemPerPage: [{ page: 0, anchorAssetId: undefined }],
  exportSapPremiseIds: { result: undefined, error: undefined },
  exportDialogVisible: false,
  importUtilizationUnits: { result: false, error: undefined },
  importDialogVisible: false,
  uuAssets: { result: undefined, error: undefined },
  childrenAssetsByUU: { result: undefined, error: undefined, loading: false },
  installationCreate: { result: undefined, error: undefined, loading: false },
  installationUpdate: { result: false, error: undefined, loading: false },
  BPDesignation: { result: undefined, error: undefined, loading: false },
  createAssignedUser: { result: undefined, error: undefined, loading: false },
  visibleModalAssignedUser: false,
  updateAssignedUser: { result: undefined, error: undefined, loading: false },
  deleteAssignedUser: { result: false, error: undefined },
  lastAssignedUserItemPerPage: { page: 0, anchorAssetId: null },
  lastBpProductionItemPerPage: { page: 0, anchorAssetId: null },
  lastBpGridItemPerPage: { page: 0, anchorAssetId: null },
  lastUuBpItemPerPage: { page: 0, anchorAssetId: null },
};

export const ekaSlice = createSlice({
  name: 'eka',
  initialState,
  reducers: {
    setAnchorAssetId: (state, action: PayloadAction<string | null>) => {
      state.anchorAssetId = action.payload;
    },
    setEkaPagination: (state, action) => {
      state.ekaPagination = action.payload;
    },
    setLastEkaItemPerPage: (state, action) => {
      state.lastEkaItemPerPage = action.payload;
    },
    resetPremisesAndInstallations: (state) => {
      state.premisesAndInstallations.result = false;
      state.premisesAndInstallations.error = null;
    },
    resetCreateZevAsset: (state) => {
      state.createZevAsset.result = undefined;
      state.createZevAsset.error = undefined;
    },
    resetUpdateZevAsset: (state) => {
      state.updateZevAsset.result = undefined;
      state.updateZevAsset.error = undefined;
    },
    resetExportBillingData: (state) => {
      state.exportBillingData.result = undefined;
      state.exportBillingData.error = undefined;
      state.exportBillingData.loading = undefined;
    },
    resetImportMultiAssetFile: (state) => {
      state.importMultiAsset.result = undefined;
      state.importMultiAsset.error = undefined;
      state.importMultiAsset.loading = false;
    },
    setReadTimedOutMessage: (state, action) => {
      state.readTimedOutMessage = action.payload;
    },
    setBalancingPointVisibleModal: (state, action: PayloadAction<boolean>) => {
      state.balancingPointVisibleModal = action.payload;
    },
    resetCreateBalancingPointAsset: (state) => {
      state.createBalancingPointAsset.result = undefined;
      state.createBalancingPointAsset.error = undefined;
      state.createBalancingPointAsset.loading = false;
    },
    resetUpdateBalancingPointAsset: (state) => {
      state.updateBalancingPointAsset.result = undefined;
      state.updateBalancingPointAsset.error = undefined;
      state.updateBalancingPointAsset.loading = false;
    },
    resetDeleteBalancingPointAsset: (state) => {
      state.deleteBalancingPointAsset.error = undefined;
      state.deleteBalancingPointAsset.result = false;
    },
    resetCreateUtilizationUnitAsset: (state) => {
      state.createUtilizationUnitAsset.result = undefined;
      state.createUtilizationUnitAsset.error = undefined;
      state.createUtilizationUnitAsset.loading = false;
    },
    setUtilizationUnitVisibleModal: (state, action) => {
      state.utilizationUnitVisibleModal = action.payload;
    },
    resetUpdateUtilizationUnitAsset: (state) => {
      state.updateUtilizationUnitAsset.result = undefined;
      state.updateUtilizationUnitAsset.error = undefined;
      state.updateUtilizationUnitAsset.loading = false;
    },
    resetDeleteUtilizationUnitAsset: (state) => {
      state.deleteUtilizationUnitAsset.result = false;
      state.deleteUtilizationUnitAsset.error = undefined;
    },
    resetCreateEkaPremise: (state) => {
      state.createEkaPremise.result = undefined;
      state.createEkaPremise.error = undefined;
      state.createEkaPremise.loading = false;
    },
    resetUpdateEkaPremise: (state) => {
      state.updateEkaPremise.result = undefined;
      state.updateEkaPremise.error = undefined;
      state.updateEkaPremise.loading = false;
    },
    setLastUuConsumptionItemPerPage: (state, action) => {
      state.lastUuConsumptionItemPerPage = action.payload;
    },
    setLastUuOwnerItemPerPage: (state, action) => {
      state.lastUuOwnerItemPerPage = action.payload;
    },
    setExportDialogVisible: (state, action) => {
      state.exportDialogVisible = action.payload;
    },
    resetExportSapPremiseIds: (state) => {
      state.exportSapPremiseIds = { result: undefined, error: undefined };
    },
    resetImportUtilizationUnits: (state) => {
      state.importUtilizationUnits = { result: false, error: undefined };
    },
    setImportDialogVisible: (state, action) => {
      state.importDialogVisible = action.payload;
    },
    resetInstallationCreate: (state) => {
      state.installationCreate = { result: undefined, error: undefined, loading: false };
    },
    resetInstallationUpdate: (state) => {
      state.installationUpdate = { result: false, error: undefined, loading: false };
    },
    resetBPDesignation: (state) => {
      state.BPDesignation = { result: undefined, error: undefined, loading: false };
    },
    resetCreateAssignedUser: (state) => {
      state.createAssignedUser = { result: undefined, error: undefined, loading: false };
    },
    setVisibleModalAssignedUser: (state, action) => {
      state.visibleModalAssignedUser = action.payload;
    },
    resetUpdateAssignedUser: (state) => {
      state.updateAssignedUser = { result: undefined, error: undefined, loading: false };
    },
    resetDeleteAssignedUser: (state) => {
      state.deleteAssignedUser = { result: false, error: undefined };
    },
    setLastAssignedUserItemPerPage: (state, action) => {
      state.lastAssignedUserItemPerPage = action.payload;
    },
    setLastBpProductionItemPerPage: (state, action) => {
      state.lastBpProductionItemPerPage = action.payload;
    },
    setLastBpGridItemPerPage: (state, action) => {
      state.lastBpGridItemPerPage = action.payload;
    },
    setLastUuBpItemPerPage: (state, action) => {
      state.lastUuBpItemPerPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPropertyAssets.fulfilled, (state, action: PayloadAction<PropertyAssetModel[]>) => {
        state.allPropertyAssets = action.payload;
      })
      .addCase(fetchPropertyAssetsWithZevAssets.pending, (state) => {
        state.loadingWithZev = true;
      })
      .addCase(
        fetchPropertyAssetsWithZevAssets.fulfilled,
        (state, action: PayloadAction<PropertyAssetsWithZevAssetsResponse>) => {
          state.propertyAssetsWithZevAssets = action.payload;
          state.loadingWithZev = false;
        }
      )
      .addCase(fetchPropertyAssetsWithZevAssets.rejected, (state) => {
        state.propertyAssetsWithZevAssets = {
          propertiesWithZevAssets: [],
          totalNumberOfAssets: 0,
        };
        state.loadingWithZev = false;
      })
      .addCase(exportZevTimeSeries.fulfilled, (state, action: PayloadAction<EkaExportZevTimeSeriesResponse>) => {
        state.ekaExportZev.ekaExportZevTimeSeries = action.payload;
      })
      .addCase(exportZevTimeSeries.rejected, (state, action: PayloadAction<ExportZevTimeSeriesErrorCodes>) => {
        state.ekaExportZev.exportZevTimeSeriesError = action.payload;
      })
      .addCase(createPremisesAndInstallations.pending, (state) => {
        state.premisesAndInstallations.result = false;
        state.premisesAndInstallations.error = null;
        state.premisesAndInstallations.loading = true;
      })
      .addCase(createPremisesAndInstallations.fulfilled, (state, action: PayloadAction<boolean>) => {
        state.premisesAndInstallations.result = action.payload;
        state.premisesAndInstallations.error = null;
        state.premisesAndInstallations.loading = false;
      })
      .addCase(
        createPremisesAndInstallations.rejected,
        (state, action: PayloadAction<PremisesAndInstallationsCreationErrorCodes>) => {
          state.premisesAndInstallations.result = false;
          state.premisesAndInstallations.error = action.payload;
          state.premisesAndInstallations.loading = false;
        }
      )
      .addCase(createZevAsset.pending, (state) => {
        state.createZevAsset.loading = true;
      })
      .addCase(createZevAsset.fulfilled, (state, action) => {
        state.createZevAsset.result = action.payload as unknown as CreateZevAsset;
        state.createZevAsset.loading = false;
      })
      .addCase(createZevAsset.rejected, (state, action) => {
        state.createZevAsset.error = action.payload;
        state.createZevAsset.loading = false;
      })
      .addCase(updateZevAsset.fulfilled, (state, action) => {
        state.updateZevAsset.result = action.payload as UpdateZevAsset_ekaUpdateZev_Asset;
      })
      .addCase(updateZevAsset.rejected, (state, action) => {
        state.updateZevAsset.error = action.payload as ZevUpdateErrorCodes;
      })
      .addCase(exportZevBillingData.pending, (state) => {
        state.exportBillingData.result = undefined;
        state.exportBillingData.loading = true;
        state.exportBillingData.error = undefined;
      })
      .addCase(exportZevBillingData.fulfilled, (state, action) => {
        state.exportBillingData.result = action.payload as ExportZevBillingData_ekaExportZevBillingData;
        state.exportBillingData.loading = false;
        state.exportBillingData.error = undefined;
      })
      .addCase(exportZevBillingData.rejected, (state, action) => {
        state.exportBillingData.result = undefined;
        state.exportBillingData.error = action.payload as ExportZevBillingDataErrorCodes;
        state.exportBillingData.loading = false;
      })
      .addCase(importMultiAssetFile.fulfilled, (state, action) => {
        state.importMultiAsset.error = undefined;
        state.importMultiAsset.result = action.payload;
        state.importMultiAsset.loading = false;
      })
      .addCase(importMultiAssetFile.rejected, (state, action) => {
        state.importMultiAsset.error = action.payload;
        state.importMultiAsset.result = undefined;
        state.importMultiAsset.loading = false;
      })
      .addCase(fetchZevAsset.fulfilled, (state, action: PayloadAction<ZevAssetModel>) => {
        state.zevAsset = action.payload;
      })
      .addCase(fetchChildrenAssetsByZev.pending, (state, action) => {
        const type = action.meta?.arg?.type;
        state[type + 'Loading'] = true;
        state[type] = {
          childrenAssets: undefined,
          totalNumberOfAssets: 0,
        };
      })
      .addCase(fetchChildrenAssetsByZev.fulfilled, (state, action) => {
        const type = action.meta?.arg?.type;
        state[type] = action.payload;
        state[type + 'Loading'] = false;
      })
      .addCase(fetchPropertyAsset.fulfilled, (state, action) => {
        state.propertyAsset = action.payload as PropertyAssetModel;
      })
      .addCase(createBalancingPointAsset.pending, (state) => {
        state.createBalancingPointAsset.result = undefined;
        state.createBalancingPointAsset.error = undefined;
        state.createBalancingPointAsset.loading = true;
      })
      .addCase(createBalancingPointAsset.fulfilled, (state, action) => {
        state.createBalancingPointAsset.result =
          action.payload as CreateBalancingPointAsset_ekaCreateBalancingPoint_Asset;
        state.createBalancingPointAsset.error = undefined;
        state.createBalancingPointAsset.loading = false;
      })
      .addCase(createBalancingPointAsset.rejected, (state, action) => {
        state.createBalancingPointAsset.error = action.payload as BalancingPointAssetCreateErrorCodes;
        state.createBalancingPointAsset.result = undefined;
        state.createBalancingPointAsset.loading = false;
      })
      .addCase(updateBalancingPointAsset.pending, (state) => {
        state.createBalancingPointAsset.error = undefined;
        state.createBalancingPointAsset.result = undefined;
        state.createBalancingPointAsset.loading = true;
      })
      .addCase(updateBalancingPointAsset.fulfilled, (state, action) => {
        state.updateBalancingPointAsset.result =
          action.payload as UpdateBalancingPointAsset_ekaUpdateBalancingPoint_Asset;
        state.updateBalancingPointAsset.error = undefined;
        state.updateBalancingPointAsset.loading = false;
      })
      .addCase(updateBalancingPointAsset.rejected, (state, action) => {
        state.updateBalancingPointAsset.result = undefined;
        state.updateBalancingPointAsset.error = action.payload['__typename'] as BalancingPointAssetUpdateErrorCodes;
        state.updateBalancingPointAsset.loading = false;
      })
      .addCase(deleteBalancingPointAsset.fulfilled, (state, action) => {
        state.deleteBalancingPointAsset.result = action.payload as boolean;
        state.deleteBalancingPointAsset.error = undefined;
      })
      .addCase(deleteBalancingPointAsset.rejected, (state, action) => {
        state.deleteBalancingPointAsset.result = undefined;
        state.deleteBalancingPointAsset.error = action.payload;
      })
      .addCase(createUtilizationUnitAsset.pending, (state) => {
        state.createUtilizationUnitAsset.result = undefined;
        state.createUtilizationUnitAsset.error = undefined;
        state.createUtilizationUnitAsset.loading = true;
      })
      .addCase(createUtilizationUnitAsset.fulfilled, (state, action) => {
        state.createUtilizationUnitAsset.result =
          action.payload as CreateUtilizationUnitAsset_ekaCreateUtilizationUnit_Asset;
        state.createUtilizationUnitAsset.error = undefined;
        state.createUtilizationUnitAsset.loading = false;
      })
      .addCase(createUtilizationUnitAsset.rejected, (state, action) => {
        state.createUtilizationUnitAsset.result = undefined;
        state.createUtilizationUnitAsset.error = action.payload as UtilizationUnitAssetCreateErrorCodes;
        state.createUtilizationUnitAsset.loading = false;
      })
      .addCase(updateUtilizationUnitAsset.pending, (state) => {
        state.updateUtilizationUnitAsset.result = undefined;
        state.updateUtilizationUnitAsset.error = undefined;
        state.updateUtilizationUnitAsset.loading = true;
      })
      .addCase(updateUtilizationUnitAsset.fulfilled, (state, action) => {
        state.updateUtilizationUnitAsset.result =
          action.payload as UpdateUtilizationUnitAsset_ekaUpdateUtilizationUnit_Asset;
        state.updateUtilizationUnitAsset.error = undefined;
        state.updateUtilizationUnitAsset.loading = true;
      })
      .addCase(updateUtilizationUnitAsset.rejected, (state, action) => {
        state.updateUtilizationUnitAsset.result = undefined;
        state.updateUtilizationUnitAsset.error = action.payload as UtilizationUnitUpdateErrorCodes;
        state.updateUtilizationUnitAsset.loading = true;
      })
      .addCase(deleteUtilizationUnitAsset.fulfilled, (state, action) => {
        state.deleteUtilizationUnitAsset.result = action.payload as boolean;
        state.deleteUtilizationUnitAsset.error = undefined;
      })
      .addCase(deleteUtilizationUnitAsset.rejected, (state, action) => {
        state.deleteUtilizationUnitAsset.error = action.payload as UtilizationUnitAssetDeleteErrorCodes;
        state.deleteUtilizationUnitAsset.result = undefined;
      })
      .addCase(createEkaPremise.fulfilled, (state, action) => {
        state.createEkaPremise.result = action.payload as CreatePremise_ekaCreatePremise_Premise;
        state.createEkaPremise.error = undefined;
        state.createEkaPremise.loading = false;
      })
      .addCase(createEkaPremise.rejected, (state, action) => {
        state.createEkaPremise.error = action.payload as EkaPremiseCreateErrorCodes;
        state.createEkaPremise.result = undefined;
        state.createEkaPremise.loading = false;
      })
      .addCase(createEkaPremise.pending, (state) => {
        state.createEkaPremise.result = undefined;
        state.createEkaPremise.error = undefined;
        state.createEkaPremise.loading = true;
      })
      .addCase(updateEkaPremise.fulfilled, (state, action) => {
        state.updateEkaPremise.result = action.payload as UpdatePremise_ekaUpdatePremise_OkResult;
        state.updateEkaPremise.error = undefined;
        state.updateEkaPremise.loading = false;
      })
      .addCase(updateEkaPremise.rejected, (state, action) => {
        state.updateEkaPremise.error = action.payload as EkaPremiseUpdateErrorCodes;
        state.updateEkaPremise.result = undefined;
        state.updateEkaPremise.loading = false;
      })
      .addCase(updateEkaPremise.pending, (state) => {
        state.updateEkaPremise.result = undefined;
        state.updateEkaPremise.error = undefined;
        state.updateEkaPremise.loading = true;
      })
      .addCase(exportSapPremiseIds.fulfilled, (state, action) => {
        state.exportSapPremiseIds.result = action.payload as AmendSapPremiseIds_ekaAmendSapPremiseIds;
        state.exportSapPremiseIds.error = undefined;
      })
      .addCase(exportSapPremiseIds.rejected, (state, action) => {
        state.exportSapPremiseIds.result = undefined;
        state.exportSapPremiseIds.error = action.payload as unknown as ExportSapPremiseIdsErrorCodes[];
      })
      .addCase(importUtilizationUnits.fulfilled, (state, action) => {
        state.importUtilizationUnits.result = action.payload as boolean;
        state.importUtilizationUnits.error = undefined;
      })
      .addCase(importUtilizationUnits.rejected, (state, action) => {
        state.importUtilizationUnits.result = undefined;
        state.importUtilizationUnits.error = action.payload as UtilizationUnitsImportErrorCodes;
      })
      .addCase(fetchUtilizationUnitAsset.fulfilled, (state, action) => {
        state.uuAssets.result = action.payload;
        state.uuAssets.error = undefined;
      })
      .addCase(fetchUtilizationUnitAsset.rejected, (state, action) => {
        state.uuAssets.result = undefined;
        state.uuAssets.error = action.payload as UtilizationUnitAssetErrorCodes;
      })
      .addCase(fetchChildrenAssetsByUtilizationUnit.pending, (state) => {
        state.childrenAssetsByUU.result = undefined;
        state.childrenAssetsByUU.error = undefined;
        state.childrenAssetsByUU.loading = true;
      })
      .addCase(fetchChildrenAssetsByUtilizationUnit.fulfilled, (state, action) => {
        state.childrenAssetsByUU.result = action.payload;
        state.childrenAssetsByUU.error = undefined;
        state.childrenAssetsByUU.loading = false;
      })
      .addCase(fetchChildrenAssetsByUtilizationUnit.rejected, (state, action) => {
        state.childrenAssetsByUU.result = undefined;
        state.childrenAssetsByUU.error = action.payload as ChildrenAssetsByUtilizationUnitErrorCodes;
        state.childrenAssetsByUU.loading = false;
      })
      .addCase(createInstallation.pending, (state) => {
        state.installationCreate.loading = true;
      })
      .addCase(createInstallation.fulfilled, (state, action) => {
        state.installationCreate.result = action.payload as unknown as CreateInstallation_ekaCreateInstallation;
      })
      .addCase(createInstallation.rejected, (state, action) => {
        state.installationCreate.error = action.payload;
      })
      .addCase(updateInstallation.pending, (state) => {
        state.installationUpdate.loading = true;
      })
      .addCase(updateInstallation.fulfilled, (state, action) => {
        state.installationUpdate.result = action.payload;
      })
      .addCase(updateInstallation.rejected, (state, action) => {
        state.installationUpdate.error = action.payload;
      })
      .addCase(generateBalancingPointDesignation.pending, (state) => {
        state.BPDesignation.loading = true;
      })
      .addCase(generateBalancingPointDesignation.fulfilled, (state, action) => {
        state.BPDesignation.result =
          action.payload as GenerateBalancingPointDesignation_ekaGenerateBalancingPointDesignation_EkaGenerateBalancingPointDesignationSuccess;
      })
      .addCase(generateBalancingPointDesignation.rejected, (state, action) => {
        state.BPDesignation.error = action.payload as BalancingPointDesignationErrorCodes;
      })
      .addCase(createAssignedUser.pending, (state) => {
        state.createAssignedUser.loading = true;
      })
      .addCase(createAssignedUser.fulfilled, (state, action) => {
        state.createAssignedUser.result = action.payload as CreateAssignedUser_ekaCreateUser_Asset;
      })
      .addCase(createAssignedUser.rejected, (state, action) => {
        state.createAssignedUser.error = action.payload as unknown as
          | AssignedUserUpdateErrorCodes
          | AssignedUserUpdateErrorCodes[];
      })
      .addCase(updateAssignedUser.pending, (state) => {
        state.updateAssignedUser.loading = true;
      })
      .addCase(updateAssignedUser.fulfilled, (state, action) => {
        state.updateAssignedUser.result = action.payload as UpdateAssignedUser_ekaUpdateUser_Asset;
      })
      .addCase(updateAssignedUser.rejected, (state, action) => {
        state.updateAssignedUser.error = action.payload as
          | AssignedUserUpdateErrorCodes
          | AssignedUserUpdateErrorCodes[];
      })
      .addCase(deleteAssignedUser.fulfilled, (state, action) => {
        state.deleteAssignedUser.result = action.payload as boolean;
      })
      .addCase(deleteAssignedUser.rejected, (state, action) => {
        state.deleteAssignedUser.error = action.payload as
          | AssignedUserDeleteErrorCodes
          | AssignedUserDeleteErrorCodes[];
      });
  },
});

export const ekaActions = ekaSlice.actions;
export const ekaReducer = ekaSlice.reducer;
