import { EkaConfig } from '@eka/eka.types';
import { EkaConfigModel } from '@eka/models/EkaConfig.model';
import { Exclude, plainToClassFromExist, serialize } from 'class-transformer';

/**
 * For convenience we export a namespace to achieve a more fluid usage:
 * ```ts
 * class ExampleModel extends Model.fromSchema<Schema, Overrides>(config)
 * ```
 *
 * As the babel transformer does not support "real" namespaces, we use
 * a static class for grouping for the time being.
 */
export abstract class Model {
  /**
   * A function that adds model magic to actual class,
   * optionally some overrides can be typed.
   */
  static fromSchema<T>() {
    return class {
      // Decorators seem not to be allowed in anonymous classes, nevertheless we want to
      // tell `class-transformer` to not include this property, thus we flag it anyway as
      // reflection still works and afaik no other declaration method for exclusion exists.
      // @ts-ignore
      @Exclude()
      protected readonly _config!: EkaConfigModel;

      // Decorators seem not to be allowed in anonymous classes, nevertheless we want to
      // tell `class-transformer` to not include this property, thus we flag it anyway as
      // reflection still works and afaik no other declaration method for exclusion exists.
      // @ts-ignore
      @Exclude()
      protected _raw!: T;

      constructor(config: EkaConfig | EkaConfigModel) {
        // set config
        if (config instanceof EkaConfigModel) {
          this._config = config;
        } else {
          this._config = new EkaConfigModel(config);
        }

        // allow chaining
        return this;
      }

      /**
       * Delivers a plain data structure from the model.
       */
      toObject(): Readonly<T> {
        return this._raw;
      }

      /**
       * A stringified JSON view of the data.
       */
      toJSON(): string {
        return serialize(this);
      }

      /**
       * Finally set the data to the instance.
       */
      withData(data: T = {} as T): this {
        // assign data object
        this._raw = Object.freeze(data);

        // add accessors for properties defined in transfer object
        // which refer to a data property in the extending class
        const transformed = plainToClassFromExist(this, data, { excludeExtraneousValues: true });

        // return modified self
        return Object.freeze(transformed);
      }
    };
  }
}
