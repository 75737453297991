import { gql } from '@apollo/client';

export const updateAssignedUserMutation = gql`
  mutation UpdateAssignedUser($utilizationUnitId: ID!, $userAssetId: ID!, $update: EkaUserUpdate!) {
    ekaUpdateUser(utilizationUnitId: $utilizationUnitId, userAssetId: $userAssetId, update: $update) {
      ... on Asset {
        id
        name
        attributeValues {
          id
          value
          attributeType {
            id
            name
          }
        }
      }
      ... on EkaUserUpdateError {
        errors {
          __typename
          errorCode
          message
        }
      }
      ... on AccessDeniedError {
        __typename
        errorCode
      }

      ... on NotFoundError {
        __typename
        errorCode
      }
    }
  }
`;
