import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorCode } from '@typings/graphql-global';
import { ExtractErrorCodesFromCommonApiCreateUpdateQuery } from '@utils/util.types';
import { DeleteAssignedUser_ekaDeleteUser } from '../../queries/__generated__/DeleteAssignedUser';
import { deleteAssignedUserMutation } from '../../queries/deleteAssignedUser.mutation';

export type AssignedUserDeletePayload = {
  utilizationUnitId: string;
  userAssetId: string;
  relationTypeId?: string;
};

export type AssignedUserDeleteErrorCodes =
  | ExtractErrorCodesFromCommonApiCreateUpdateQuery<DeleteAssignedUser_ekaDeleteUser>
  | ErrorCode.NO_DELETION_PERMISSION
  | ErrorCode.NOT_FOUND
  | 'NotFoundError'
  | 'AccessDeniedError'
  | 'Error';

export type AssignedUserDeleteResponse = AssignedUserDeleteErrorCodes | true;

export const deleteAssignedUser = createAsyncThunk<
  AssignedUserDeleteResponse | null,
  AssignedUserDeletePayload,
  { rejectValue: AssignedUserDeleteErrorCodes }
>('assignedUser/delete', async ({ utilizationUnitId, userAssetId, relationTypeId }, { rejectWithValue }) => {
  const apolloClient = getApolloClient();

  try {
    const { data } = await apolloClient.mutate({
      mutation: deleteAssignedUserMutation,
      variables: {
        utilizationUnitId,
        userAssetId,
      },
      fetchPolicy: 'no-cache', // Use appropriate fetch policy
    });

    // Ensure there is data in the response
    if (!data) {
      throw new Error('No data in response');
    }

    if (data.ekaDeleteUser.__typename === 'DeletionResult') {
      return true;
    } else if (data.ekaDeleteUser.errors?.length) {
      return rejectWithValue(data.ekaDeleteUser.errors);
    } else {
      return rejectWithValue(data.ekaDeleteUser);
    }
  } catch (error) {
    return rejectWithValue('Error');
  }
});
