import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AssetPagination,
  AssetSearchAssetTypeIdConstraint,
  AssetSearchConstraint,
  AssetSearchConstraintType,
  AssetSearchParentConstraint,
  ChildAssetSearchConstraint,
  ChildAssetSearchConstraintType,
} from '@teci/ecockpit-frontend-gateway-api';
import { EkaConfig } from '../../eka.types';
import { BalancingPointAssetModel } from '../../models/BalancingPointAsset.model';
import { EkaConfigModel } from '../../models/EkaConfig.model';
import { UtilizationUnitAssetModel } from '../../models/UtilizationUnitAsset.model';
import {
  ChildrenAssetsByZev_assets_AssetsResult,
  ChildrenAssetsByZev_assets_SearchError,
  ChildrenAssetsByZevVariables,
} from '../../queries/__generated__/ChildrenAssetsByZev';
import { childrenAssetsByZevQuery } from '../../queries/zevAssetDetails.query';

export type ChildrenAssetsByZevPayload = {
  config: EkaConfig;
  relationTypeId: string;
  pagination: AssetPagination;
  childrenAssetTypeId: string;
  zevId: string;
  nameSubstring: string;
  type:
    | 'utilizationUnitsConsumption'
    | 'utilizationUnitsOwner'
    | 'balancingPointsPowerGrid'
    | 'balancingPointsProduction';
};

export type ChildrenAssetsByZevErrorCodes = ChildrenAssetsByZev_assets_SearchError['__typename'];

export const prepareChildrenAssetsByZevVariables = (
  relationTypeId: string,
  pagination: AssetPagination,
  childrenAssetTypeId: string,
  zevId: string,
  nameSubstring: string
): ChildrenAssetsByZevVariables => {
  const assetTypeIdConstraint: AssetSearchAssetTypeIdConstraint = {
    assetTypeId: childrenAssetTypeId,
    assetTypeIds: null,
  };

  const parentConstraint: AssetSearchParentConstraint = {
    parentId: zevId,
    relationTypeId,
  };

  const constraints: AssetSearchConstraint[] = [
    {
      constraintType: AssetSearchConstraintType.ASSET_TYPE_ID,
      assetTypeIdConstraint,
      childrenAssetTypeIdConstraint: null,
      parentConstraint: null,
      assetSearchNameSubstringConstraint: null,
    },
    {
      constraintType: AssetSearchConstraintType.PARENT,
      childrenAssetTypeIdConstraint: null,
      assetTypeIdConstraint: null,
      parentConstraint,
      assetSearchNameSubstringConstraint: null,
    },
  ];
  if (nameSubstring) {
    constraints.push({
      constraintType: AssetSearchConstraintType.ASSET_SEARCH_SUBSTRING,
      assetTypeIdConstraint: null,
      childrenAssetTypeIdConstraint: null,
      parentConstraint: null,
      assetSearchNameSubstringConstraint: { substring: nameSubstring },
    });
  }

  const childAssetSearchConstraints: ChildAssetSearchConstraint[] = [
    {
      constraintType: ChildAssetSearchConstraintType.ASSET_TYPE_ID,
      assetTypeIdConstraint: {
        assetTypeId: childrenAssetTypeId,
        assetTypeIds: null,
      },
    },
  ];

  return { constraints, childAssetSearchConstraints, relationTypeId, pagination };
};

export const mapChildrenAssets: (
  { assetsGroupedByAssetType, totalNumberOfAssets }: ChildrenAssetsByZev_assets_AssetsResult,
  config: EkaConfig
) => { childrenAssets: Array<BalancingPointAssetModel | UtilizationUnitAssetModel>; totalNumberOfAssets: number } = (
  { assetsGroupedByAssetType, totalNumberOfAssets }: ChildrenAssetsByZev_assets_AssetsResult,
  config: EkaConfig
) => {
  const _config = new EkaConfigModel(config);

  return {
    childrenAssets: assetsGroupedByAssetType.flatMap(({ assets }) =>
      assets
        .filter(({ children }) => children.__typename === 'AssetsResult')
        .map((asset) => {
          return _config.isBalancingPointAssetType(asset.type.id)
            ? new BalancingPointAssetModel(config).withData(asset)
            : new UtilizationUnitAssetModel(config).withData(asset);
        })
    ),
    totalNumberOfAssets,
  };
};

export const fetchChildrenAssetsByZev = createAsyncThunk<
  { childrenAssets: Array<BalancingPointAssetModel | UtilizationUnitAssetModel>; totalNumberOfAssets: number },
  ChildrenAssetsByZevPayload,
  { rejectValue: ChildrenAssetsByZevErrorCodes }
>('childrenAssets/fetchByZev', async (payload, { rejectWithValue }) => {
  const apolloClient = getApolloClient();

  try {
    const { data } = await apolloClient.query({
      query: childrenAssetsByZevQuery,
      variables: prepareChildrenAssetsByZevVariables(
        payload.relationTypeId,
        payload.pagination,
        payload.childrenAssetTypeId,
        payload.zevId,
        payload.nameSubstring
      ),
      fetchPolicy: 'no-cache',
    });

    if (data?.assets?.assetsGroupedByAssetType) {
      return mapChildrenAssets(data.assets, payload.config);
    } else {
      return rejectWithValue(data.assets);
    }
  } catch (error) {
    return rejectWithValue('SearchError');
  }
});
