import { getFeatureConfig } from '@components/ConfigProvider/featureConfig.queries';
import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchFeatureConfig = createAsyncThunk(
  'config/fetchFeatureConfig',
  async (feature: string, { rejectWithValue }) => {
    try {
      const apolloClient = getApolloClient();

      const response = await apolloClient.query({
        query: getFeatureConfig,
        variables: { id: feature },
        fetchPolicy: 'network-only',
      });

      return response.data.feature.config;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
