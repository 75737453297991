import { RelationTypes } from '@components/RelationTypes/relationTypes.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchFeatureConfig } from './thunks/fetchFeatureConfig';
import { fetchRelationTypes } from './thunks/fetchRelationTypes';

interface GlobalSlice {
  relationTypes?: RelationTypes;
  configState?: GraphQLObject;
  headerTitles: {
    title: string;
    subtitle: string;
    subtitleLink?: string;
  };
}

const initialState: GlobalSlice = {
  relationTypes: undefined,
  configState: undefined,
  headerTitles: {
    title: '',
    subtitle: '',
    subtitleLink: undefined,
  },
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setHeaderTitles: (state, action) => {
      state.headerTitles = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRelationTypes.fulfilled, (state, action: PayloadAction<RelationTypes | undefined>) => {
        if (action.payload !== undefined) {
          state.relationTypes = action.payload;
        }
      })
      .addCase(fetchFeatureConfig.fulfilled, (state, action: PayloadAction<GraphQLObject | undefined>) => {
        if (action.payload !== undefined) {
          state.configState = action.payload;
        }
      });
  },
});

export const globalActions = globalSlice.actions;
export const globalReducer = globalSlice.reducer;
