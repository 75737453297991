import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { EkaAmendSapPremiseIdsErrorCode, ErrorCode } from '@typings/graphql-global';
import { AmendSapPremiseIds_ekaAmendSapPremiseIds } from '../../queries/__generated__/AmendSapPremiseIds';
import { amendSapPremiseIdsMutation } from '../../queries/amendSapPremiseIds.mutation';

type ExportSapPremiseIdsPayload = {
  zevId: string;
  csvFile: File;
};

export type ExportSapPremiseIdsErrorCodes =
  | ErrorCode.NOT_FOUND
  | EkaAmendSapPremiseIdsErrorCode.CSV_FILE_INVALID
  | EkaAmendSapPremiseIdsErrorCode.UNKNOWN_ERROR;

export type ExportSapPremiseIdsResponse = ExportSapPremiseIdsErrorCodes | AmendSapPremiseIds_ekaAmendSapPremiseIds;

export const exportSapPremiseIds = createAsyncThunk<
  ExportSapPremiseIdsResponse,
  ExportSapPremiseIdsPayload,
  { rejectValue: ExportSapPremiseIdsErrorCodes }
>('sapPremiseIds/export', async ({ zevId, csvFile }, { rejectWithValue }) => {
  const apolloClient = getApolloClient();

  try {
    const { data } = await apolloClient.mutate({
      mutation: amendSapPremiseIdsMutation,
      variables: { zevId, csvFile },
      fetchPolicy: 'no-cache',
    });

    if (data?.ekaAmendSapPremiseIds.__typename === 'EkaAmendSapPremiseIdsSuccess') {
      return data.ekaAmendSapPremiseIds;
    } else {
      return rejectWithValue(data.ekaAmendSapPremiseIds.errors);
    }
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
