import { Model } from '@utils/model.utils';
import { Expose } from 'class-transformer';
import { UserPermission, EkaConfigAssignedUser } from '../eka.types';
import { ChildrenAssetsByUtilizationUnit_assets_AssetsResult_assetsGroupedByAssetType_assets as UtilizationUnitUserAssetSchema } from '../queries/__generated__/ChildrenAssetsByUtilizationUnit';

export class UserModel extends Model.fromSchema<UtilizationUnitUserAssetSchema>() {
  private readonly _permissions: Set<UserPermission> = new Set<UserPermission>();
  private _attributes?: Map<string, string>;
  private _assignedUserConfig: EkaConfigAssignedUser | undefined;

  @Expose()
  get id(): string {
    return this._raw.id;
  }

  @Expose()
  get username(): string {
    if (!this._assignedUserConfig) return '';
    return this._raw.name;
  }

  @Expose()
  get moveInDate(): Date | undefined {
    if (!this._assignedUserConfig) return undefined;
    const date = this._attributes?.get(this._assignedUserConfig.moveInAttributeTypeId);

    return !date ? undefined : new Date(date);
  }

  @Expose()
  get moveOutDate(): Date | undefined {
    if (!this._assignedUserConfig) return undefined;
    const date = this._attributes?.get(this._assignedUserConfig.moveOutAttributeTypeId);

    return !date ? undefined : new Date(date);
  }

  @Expose()
  get type(): string {
    return this._raw.type.id;
  }

  withData(data: UtilizationUnitUserAssetSchema): this {
    this._assignedUserConfig = this._config.getAssignedUserConfigByAssetTypeId(data.type.id);
    this._attributes = new Map(data.attributeValues.map(({ attributeType, value }) => [attributeType.id, value]));

    return super.withData(data);
  }

  /**
   * Checks if the user has the given permission.
   */
  hasPermission(permission: UserPermission): boolean {
    return this._permissions.has(permission);
  }

  /**
   * Checks if the user has all of the given permissions.
   */
  hasAllPermissions(permissions: UserPermission[]): boolean {
    return permissions.every((permission) => this._permissions.has(permission));
  }

  /**
   * Checks if the user has at least one of the given permissions.
   */
  hasAnyPermissions(permissions: UserPermission[]): boolean {
    return permissions.some((permission) => this._permissions.has(permission));
  }
}
