import { gql } from '@apollo/client';

export const createUtilizationUnitAssetMutation = gql`
  mutation CreateUtilizationUnitAsset($utilizationUnitAsset: EkaUtilizationUnitCreation!) {
    ekaCreateUtilizationUnit(utilizationUnit: $utilizationUnitAsset) {
      ... on Asset {
        id
        name
        attributeValues {
          id
          value
          attributeType {
            id
            name
          }
        }
      }
      ... on EkaUtilizationUnitCreationError {
        errors {
          __typename
          errorCode
          message
        }
      }
      ... on AccessDeniedError {
        __typename
        errorCode
      }
    }
  }
`;
