import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DeleteEkaUtilizationUnit,
  DeleteEkaUtilizationUnit_ekaDeleteUtilizationUnit,
  DeleteEkaUtilizationUnitVariables,
} from '../../queries/__generated__/DeleteEkaUtilizationUnit';
import { deleteUtilizationUnitAssetMutation } from '../../queries/deleteUtilizationUnitAsset.mutation';

export type UtilizationUnitAssetDeletePayload = { utilizationUnitId: string };

export type UtilizationUnitAssetDeleteErrorCodes =
  | Exclude<
      DeleteEkaUtilizationUnit_ekaDeleteUtilizationUnit['__typename'],
      Extract<DeleteEkaUtilizationUnit_ekaDeleteUtilizationUnit['__typename'], 'DeletionResult'>
    >
  | 'Error';

export type UtilizationUnitAssetDeleteResponse = UtilizationUnitAssetDeleteErrorCodes | true;

export const deleteUtilizationUnitAsset = createAsyncThunk<
  UtilizationUnitAssetDeleteResponse,
  UtilizationUnitAssetDeletePayload,
  { rejectValue: UtilizationUnitAssetDeleteErrorCodes }
>(
  'utilizationUnitAsset/delete',
  async ({ utilizationUnitId }: UtilizationUnitAssetDeletePayload, { rejectWithValue }) => {
    const apolloClient = getApolloClient();

    try {
      const { data } = await apolloClient.mutate<DeleteEkaUtilizationUnit, DeleteEkaUtilizationUnitVariables>({
        mutation: deleteUtilizationUnitAssetMutation,
        variables: { utilizationUnitId },
        fetchPolicy: 'no-cache',
      });

      if (data?.ekaDeleteUtilizationUnit.__typename === 'DeletionResult') {
        return true;
      } else {
        return rejectWithValue(data?.ekaDeleteUtilizationUnit['__typename'] ?? 'Error');
      }
    } catch (error) {
      return rejectWithValue('Error');
    }
  }
);
