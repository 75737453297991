import { gql } from '@apollo/client';

export const assetChildrenDetailsFragment = gql`
  fragment AssetChildrenDetails on AssetsResult {
    assetsGroupedByAssetType {
      numberOfAssets
      assetType {
        id
      }
      assets {
        id
        name
        type {
          id
          name
        }
        attributeValues {
          id
          value
          attributeType {
            id
            name
            orderIndex
          }
        }
      }
    }
  }
`;

export const zevAssetsByPropertyQuery = gql`
  query ZevAssetsByProperty(
    $constraints: [AssetSearchConstraint]
    $childAssetSearchConstraints: [ChildAssetSearchConstraint]
    $relationTypeId: ID!
    $pagination: AssetPagination
  ) {
    assets(constraints: $constraints, pagination: $pagination) {
      ... on AssetsResult {
        totalNumberOfAssets
        assetsGroupedByAssetType {
          numberOfAssets
          assetType {
            id
            name
          }
          assets {
            id
            name
            type {
              id
            }
            attributeValues {
              id
              value
              attributeType {
                id
                name
                orderIndex
              }
            }
            children(constraints: $childAssetSearchConstraints, relationTypeId: $relationTypeId) {
              ... on AssetsResult {
                ...AssetChildrenDetails
              }
            }
          }
        }
      }
    }
  }

  ${assetChildrenDetailsFragment}
`;
