import { gql } from '@apollo/client';

export const importUtilizationUnitsMutation = gql`
  mutation ImportUtilizationUnits($zevId: ID!, $csvFile: Upload!) {
    ekaImportUtilizationUnits(zevId: $zevId, csvFile: $csvFile) {
      __typename
      ... on RequestSubmitted {
        ok
      }
      ... on AccessDeniedError {
        errorCode
      }
      ... on NotFoundError {
        errorCode
      }
    }
  }
`;
