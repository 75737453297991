import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { EkaInstallationUpdateErrorCode, ErrorCode } from '@typings/graphql-global';
import { ExtractErrorCodesFromCommonApiCreateUpdateQuery } from '@utils/util.types';
import {
  UpdateInstallation_ekaUpdateInstallation,
  UpdateInstallation_ekaUpdateInstallation_OkResult,
} from '../../queries/__generated__/UpdateInstallation';
import { updateInstallationMutation } from '../../queries/updateInstallation.mutation';
import { InstallationCreateErrorCodes } from './createInstallation';

export type EkaInstallationUpdatePayload = {
  balancingPointId: string;
};

export type EkaInstallationUpdateErrorCodes =
  | EkaInstallationUpdateErrorCode
  | ExtractErrorCodesFromCommonApiCreateUpdateQuery<UpdateInstallation_ekaUpdateInstallation>
  | ErrorCode.NO_UPDATE_PERMISSION
  | ErrorCode.NOT_FOUND
  | 'AccessDeniedError'
  | 'NotFoundError'
  | 'Error';

export type EkaInstallationUpdateResponse =
  | EkaInstallationUpdateErrorCodes
  | UpdateInstallation_ekaUpdateInstallation_OkResult;

export const updateInstallation = createAsyncThunk<boolean, EkaInstallationUpdatePayload, { rejectValue }>(
  'updateInstallation/update',
  async ({ balancingPointId }, { rejectWithValue, dispatch }) => {
    const apolloClient = getApolloClient();

    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: updateInstallationMutation,
        variables: { balancingPointId },
      });

      // Ensure there's data in the response
      if (!data) {
        throw new Error('No data in response');
      }

      if (data.ekaUpdateInstallation.__typename === 'OkResult') {
        return true;
      } else if (errors?.length) {
        return rejectWithValue('Error');
      } else {
        if (data.ekaUpdateInstallation.errors) {
          return rejectWithValue(data.ekaUpdateInstallation.errors);
        }
        return rejectWithValue(data.ekaUpdateInstallation.errorCode as InstallationCreateErrorCodes);
      }
    } catch (error) {
      return rejectWithValue('Error');
    }
  }
);
