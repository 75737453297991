import { gql } from '@apollo/client';

export const getJobStatusQuery = gql`
  query GetJobStatus($zevId: ID!) {
    ekaGetJobStatus(zevId: $zevId) {
      __typename
      ... on ZevJobStatusResult {
        zevJobStatusList {
          status
          jobType
          timestamp
          fileName
          errors {
            errorCode
            message
          }
        }
      }
      ... on AccessDeniedError {
        errorCode
      }
      ... on NotFoundError {
        errorCode
      }
    }
  }
`;
