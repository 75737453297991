import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { EkaBalancingPointCreation } from '@teci/ecockpit-frontend-gateway-api';
import { ErrorCode } from '@typings/graphql-global';
import { ExtractErrorCodesFromCommonApiCreateUpdateQuery } from '@utils/util.types';
import {
  CreateBalancingPointAsset,
  CreateBalancingPointAsset_ekaCreateBalancingPoint,
  CreateBalancingPointAsset_ekaCreateBalancingPoint_Asset,
  CreateBalancingPointAssetVariables,
} from '../../queries/__generated__/CreateBalancingPointAsset';
import { createBalancingPointAssetMutation } from '../../queries/createBalancingPointAsset.mutation';
import { ekaActions } from '../eakSlice';

export type BalancingPointAssetCreatePayload = EkaBalancingPointCreation;

export type BalancingPointAssetCreateErrorCodes =
  | ExtractErrorCodesFromCommonApiCreateUpdateQuery<CreateBalancingPointAsset_ekaCreateBalancingPoint>
  | ErrorCode.NOT_FOUND
  | ErrorCode.NO_UPDATE_PERMISSION
  | 'AccessDeniedError'
  | 'Error';

export type BalancingPointAssetCreateResponse =
  | BalancingPointAssetCreateErrorCodes
  | CreateBalancingPointAsset_ekaCreateBalancingPoint_Asset;

export const createBalancingPointAsset = createAsyncThunk<
  BalancingPointAssetCreateResponse,
  BalancingPointAssetCreatePayload,
  { rejectValue: BalancingPointAssetCreateErrorCodes }
>(
  'balancingPointAsset/create',
  async (
    { parentAssetId, start, end, assetTypeId, designation, balancingPointName, deviceId, serialNumber, manufacturer },
    { rejectWithValue, dispatch }
  ) => {
    const apolloClient = getApolloClient();

    try {
      const { data } = await apolloClient.mutate<CreateBalancingPointAsset, CreateBalancingPointAssetVariables>({
        mutation: createBalancingPointAssetMutation,
        variables: {
          creation: {
            parentAssetId,
            start,
            end,
            assetTypeId,
            designation: designation?.length ? designation : null,
            balancingPointName,
            deviceId,
            serialNumber,
            manufacturer,
          },
        },
        fetchPolicy: 'no-cache',
      });

      if (data && data.ekaCreateBalancingPoint.__typename === 'Asset') {
        return data.ekaCreateBalancingPoint;
      } else {
        return rejectWithValue('Error');
      }
    } catch (error) {
      return rejectWithValue('Error');
    } finally {
      dispatch(ekaActions.resetCreateBalancingPointAsset());
    }
  }
);
