import { gql } from '@apollo/client';

export const ExportZevBillingDataMutation = gql`
  mutation ExportZevBillingData(
    $zevId: ID!
    $start: Date!
    $end: Date!
    $highCorrectionFactorOption: HighCorrectionFactorOption!
  ) {
    ekaExportZevBillingData(
      zevId: $zevId
      start: $start
      end: $end
      highCorrectionFactorOption: $highCorrectionFactorOption
    ) {
      __typename
      ... on OkResult {
        ok
      }
      ... on EkaExportZevBillingDataError {
        errors {
          errorCode
          message
        }
      }
      ... on AccessDeniedError {
        errorCode
      }
      ... on NotFoundError {
        errorCode
      }
    }
  }
`;
