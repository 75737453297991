import { Model } from '@utils/model.utils';
import { Expose } from 'class-transformer';
import { EkaConfigUtilizationUnit } from '../eka.types';
import { UtilizationUnitAsset_asset as UtilizationUnitAssetSchema } from '../queries/__generated__/UtilizationUnitAsset';
import { BalancingPointAssetModel } from './BalancingPointAsset.model';
import { UserModel } from './User.model';
import { ChildrenAssetsByZev_assets_AssetsResult_assetsGroupedByAssetType_assets as ChildrenZevAssetsSchema } from '../queries/__generated__/ChildrenAssetsByZev';

export class UtilizationUnitAssetModel extends Model.fromSchema<
  UtilizationUnitAssetSchema | ChildrenZevAssetsSchema
>() {
  private readonly _attributes = new Map<string, string>();
  private _utilizationUnitConfig: EkaConfigUtilizationUnit | undefined;
  private _balancingPoints: BalancingPointAssetModel[] = [];
  private _balancingPointType: { name: string; id: string }[] = [];
  private _assignedUsers: UserModel[] = [];
  private _assignedUserType: { name: string; id: string }[] = [];

  @Expose()
  get balancingPoints(): BalancingPointAssetModel[] {
    return this._balancingPoints;
  }

  @Expose()
  get relatedBalancingPointType(): { name: string; id: string }[] {
    return this._balancingPointType;
  }

  @Expose()
  get relatedAssignedUserType(): { name: string; id: string }[] {
    return this._assignedUserType;
  }

  @Expose()
  get assignedUsers(): UserModel[] {
    return this._assignedUsers;
  }

  @Expose()
  get id(): string {
    return this._raw.id;
  }

  @Expose()
  get name(): string {
    return this._raw.name;
  }

  @Expose()
  get type(): string {
    return this._raw.type.id;
  }

  @Expose()
  get typeDesignation(): string {
    return this._config.getUtilizationUnitConfigKeyByAssetTypeId(this.type);
  }

  @Expose()
  get userRoles(): string[] {
    return this._config.utilizationUnit[this._config.getUtilizationUnitConfigKeyByAssetTypeId(this._raw.type.id)]
      .userRoles;
  }

  @Expose()
  get originalSapPremiseId(): string | undefined {
    if (this._utilizationUnitConfig === undefined) return undefined;
    const originalSapPremiseId = this._attributes?.get(this._utilizationUnitConfig.originalSapPremiseIdAttributeTypeId);
    // Check for empty strings as we're not sure if the backend does this check
    return originalSapPremiseId?.trim() === '' ? undefined : originalSapPremiseId;
  }

  @Expose()
  get premiseId(): string | undefined {
    if (this._utilizationUnitConfig === undefined) return undefined;
    const premiseId = this._attributes?.get(this._utilizationUnitConfig.premiseIdAttributeTypeId);
    // IWB-868: we need to check for empty strings as neither the asset form nor the backend seem to care
    return premiseId?.trim() === '' ? undefined : premiseId;
  }

  @Expose()
  get timestamp(): Date | undefined {
    if (this._utilizationUnitConfig === undefined) return undefined;
    const date = this._attributes?.get(this._utilizationUnitConfig.lastPremiseUpdateAttributeTypeId);
    return !date ? undefined : new Date(date);
  }

  withData(data: UtilizationUnitAssetSchema | ChildrenZevAssetsSchema): this {
    // retrieve specific config once
    this._utilizationUnitConfig = this._config.getUtilizationUnitConfigByAssetTypeId(data.type.id);
    this._balancingPointType = data.type.outgoingRelations
      .filter(({ target }) => Object.values(this._config.balancingPoint).some((type) => type.assetTypeId === target.id))
      .map(({ target }) => {
        return { id: target.id, name: target.name };
      });
    this._assignedUserType = data.type.outgoingRelations
      .filter(({ target }) => {
        return this._config.getUtilizationUnitConfigKeyByAssetTypeId(data.type.id) === 'owner'
          ? target.id === this._config.owner.assetTypeId
          : target.id === this._config.tenant.assetTypeId;
      })
      .map(({ target }) => {
        return { name: target.name, id: target.id };
      });

    // prepare the attributes map once initially
    data.attributeValues.forEach(({ attributeType, value }) => this._attributes.set(attributeType.id, value));

    // call parent method which will return the locked instance
    return super.withData(data);
  }

  isUtilizationUnitSchema(
    data: UtilizationUnitAssetSchema | ChildrenZevAssetsSchema
  ): data is UtilizationUnitAssetSchema {
    return 'childrenGroupedByAssetType' in data;
  }
}
