import { gql } from '@apollo/client';

const zevCreationResultDataFragment = gql`
  fragment ZevCreationResultData on Asset {
    id
    type {
      id
      name
    }
    name
  }
`;

export const createZevAssetMutation = gql`
  mutation CreateZevAsset(
    $propertyAssetId: ID!
    $connectionObjectId: ID!
    $billingVariant: BillingVariant!
    $computationStartDate: DateTime!
    $mainEnergyManagerGuid: String
  ) {
    zevChange: ekaCreateZev(
      propertyAssetId: $propertyAssetId
      connectionObjectId: $connectionObjectId
      billingVariant: $billingVariant
      computationStartDate: $computationStartDate
      mainEnergyManagerGuid: $mainEnergyManagerGuid
    ) {
      ... on Asset {
        ...ZevCreationResultData
      }

      ... on EkaZevCreationError {
        errors {
          errorCode
          message
        }
      }
    }
  }

  ${zevCreationResultDataFragment}
`;
