import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { BillingVariant } from '@teci/ecockpit-frontend-gateway-api';
import { ExtractErrorCodesFromCommonApiCreateUpdateQuery } from '@utils/util.types';
import { AppDispatch } from '../../../../store/store';
import {
  CreateZevAsset,
  CreateZevAsset_zevChange,
  CreateZevAsset_zevChange_AccessDeniedError,
  CreateZevAsset_zevChange_Asset,
  CreateZevAssetVariables,
} from '../../queries/__generated__/CreateZevAsset';
import { createZevAssetMutation } from '../../queries/createZevAsset.mutation';
import { ekaActions } from '../eakSlice';

export type ZevAssetCreatePayload = {
  propertyAssetId: string;
  connectionObjectId: string;
  billingVariant: BillingVariant;
  computationStartDate: string;
  mainEnergyManagerGuid?: string | null;
};

export type ZevAssetCreateErrorCodes =
  | ExtractErrorCodesFromCommonApiCreateUpdateQuery<CreateZevAsset_zevChange>
  | CreateZevAsset_zevChange_AccessDeniedError['__typename']
  | 'Error';

export const createZevAsset = createAsyncThunk<
  CreateZevAsset_zevChange_Asset,
  ZevAssetCreatePayload,
  { rejectValue: ZevAssetCreateErrorCodes; dispatch: AppDispatch }
>(
  'zevAsset/create',
  async (
    { propertyAssetId, connectionObjectId, billingVariant, computationStartDate, mainEnergyManagerGuid },
    { rejectWithValue, dispatch }
  ) => {
    const apolloClient = getApolloClient();

    try {
      const { data, errors } = await apolloClient.mutate<CreateZevAsset, CreateZevAssetVariables>({
        mutation: createZevAssetMutation,
        variables: { propertyAssetId, connectionObjectId, billingVariant, computationStartDate, mainEnergyManagerGuid },
        fetchPolicy: 'no-cache', // Optional, depending on your use case
      });

      if (data?.zevChange.__typename === 'Asset') {
        return data.zevChange; // Successful response
      } else if (data?.zevChange.__typename === 'AccessDeniedError') {
        return rejectWithValue('AccessDeniedError' as ZevAssetCreateErrorCodes);
      } else if (!data && errors?.length) {
        return rejectWithValue('Error' as ZevAssetCreateErrorCodes);
      } else {
        return rejectWithValue(data.zevChange as any);
      }
    } catch (error) {
      return rejectWithValue('Error' as ZevAssetCreateErrorCodes);
    } finally {
      setTimeout(() => dispatch(ekaActions.resetCreateZevAsset()), 1000);
    }
  }
);
