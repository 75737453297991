import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorCode } from '@typings/graphql-global';
import { ExtractErrorCodesFromCommonApiCreateUpdateQuery } from '@utils/util.types';
import {
  CreateAssignedUser_ekaCreateUser,
  CreateAssignedUser_ekaCreateUser_Asset,
} from '../../queries/__generated__/CreateAssignedUser';
import { isValid } from 'date-fns';
import { createAssignedUserMutation } from '../../queries/createAssignedUser.mutation';

export type AssignedUserCreatePayload = {
  utilizationUnitId: string;
  userId: string;
  start?: Date;
  end?: Date;
};

export type AssignedUserCreateErrorCodes =
  | ExtractErrorCodesFromCommonApiCreateUpdateQuery<CreateAssignedUser_ekaCreateUser>
  | ErrorCode.NO_CREATION_PERMISSION
  | 'AccessDeniedError'
  | 'Error';

export type AssignedUserCreateResponse =
  | AssignedUserCreateErrorCodes
  | AssignedUserCreateErrorCodes[]
  | CreateAssignedUser_ekaCreateUser_Asset;

export const createAssignedUser = createAsyncThunk<
  AssignedUserCreateResponse | null,
  AssignedUserCreatePayload,
  { rejectValue: AssignedUserCreateErrorCodes }
>('assignedUser/create', async ({ utilizationUnitId, userId, start, end }, { rejectWithValue }) => {
  const apolloClient = getApolloClient();

  const from = start && isValid(start) ? start.toJSON() : undefined;
  const until = end && isValid(end) ? end.toJSON() : undefined;

  try {
    const { data } = await apolloClient.mutate({
      mutation: createAssignedUserMutation,
      variables: {
        utilizationUnitId,
        creation: {
          userId,
          moveIn: from,
          moveOut: until,
        },
      },
      fetchPolicy: 'no-cache', // Use appropriate fetch policy
    });

    // Ensure there is data in the response
    if (!data) {
      throw new Error('No data in response');
    }

    if (data.ekaCreateUser.__typename === 'Asset') {
      return data.ekaCreateUser;
    } else if (data.ekaCreateUser.errors?.length) {
      return rejectWithValue(data.ekaCreateUser.errors);
    } else {
      return rejectWithValue(data.ekaCreateUser);
    }
  } catch (error) {
    return rejectWithValue('Error');
  }
});
