import { gql } from '@apollo/client';

export const propertyAssetQuery = gql`
  query PropertyAsset($assetId: ID!) {
    asset(assetId: $assetId) {
      id
      name
      type {
        id
      }
      attributeValues {
        id
        value
        attributeType {
          id
          name
          orderIndex
        }
      }
    }
  }
`;
