import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { EkaZevUpdateErrorCode, ErrorCode } from '@typings/graphql-global';
import { ExtractErrorCodesFromCommonApiCreateUpdateQuery } from '@utils/util.types';
import {
  UpdateZevAsset_ekaUpdateZev,
  UpdateZevAsset_ekaUpdateZev_Asset,
} from '../../queries/__generated__/UpdateZevAsset';
import { updateZevAssetMutation } from '../../queries/updateZevAsset.mutation';
import { ekaActions } from '../eakSlice';

export type ZevAssetUpdatePayload = {
  zevAssetId: string;
  computationStartDate: string;
  mainEnergyManagerGuid?: string | null;
};

export type ZevUpdateErrorCodes =
  | EkaZevUpdateErrorCode
  | ExtractErrorCodesFromCommonApiCreateUpdateQuery<UpdateZevAsset_ekaUpdateZev>
  | ErrorCode.NO_UPDATE_PERMISSION
  | 'Error';

export type ZevAssetUpdateResponse = ZevUpdateErrorCodes | UpdateZevAsset_ekaUpdateZev_Asset;

export const updateZevAsset = createAsyncThunk<
  ZevAssetUpdateResponse,
  ZevAssetUpdatePayload,
  { rejectValue: ZevUpdateErrorCodes }
>(
  'zevAsset/update',
  async ({ zevAssetId, computationStartDate, mainEnergyManagerGuid }, { rejectWithValue, dispatch }) => {
    const apolloClient = getApolloClient();

    try {
      const { data } = await apolloClient.mutate({
        mutation: updateZevAssetMutation,
        variables: { zevAssetId, computationStartDate, mainEnergyManagerGuid },
        fetchPolicy: 'no-cache',
      });

      if (data?.ekaUpdateZev.__typename === 'Asset') {
        return data.ekaUpdateZev;
      } else {
        return rejectWithValue(data?.ekaUpdateZev ?? 'Error');
      }
    } catch (error) {
      return rejectWithValue('Error');
    } finally {
      dispatch(ekaActions.resetUpdateZevAsset());
    }
  }
);
