import { gql } from '@apollo/client';

export const updateZevAssetMutation = gql`
  mutation UpdateZevAsset($zevAssetId: ID!, $computationStartDate: DateTime!, $mainEnergyManagerGuid: String) {
    ekaUpdateZev(
      zevAssetId: $zevAssetId
      computationStartDate: $computationStartDate
      mainEnergyManagerGuid: $mainEnergyManagerGuid
    ) {
      ... on Asset {
        id
        type {
          id
          name
        }
        name
      }
      ... on EkaZevUpdateError {
        errors {
          __typename
          errorCode
          message
        }
      }
      ... on AccessDeniedError {
        __typename
        errorCode
      }
      ... on NotFoundError {
        __typename
        errorCode
      }
    }
  }
`;
