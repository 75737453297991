import { gql } from '@apollo/client';

export const deleteAssignedUserMutation = gql`
  mutation DeleteAssignedUser($utilizationUnitId: ID!, $userAssetId: ID!) {
    ekaDeleteUser(utilizationUnitId: $utilizationUnitId, userAssetId: $userAssetId) {
      ... on DeletionResult {
        __typename
        ok
      }

      ... on AccessDeniedError {
        __typename
        errorCode
      }

      ... on NotFoundError {
        __typename
        errorCode
      }
    }
  }
`;
