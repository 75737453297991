import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorCode } from '@typings/graphql-global';
import { ExtractErrorCodesFromCommonApiCreateUpdateQuery } from '@utils/util.types';
import { CreateInstallation_ekaCreateInstallation } from '../../queries/__generated__/CreateInstallation';
import { createInstallationMutation } from '../../queries/createInstallation.mutation';

type InstallationCreatePayload = {
  balancingPointId: string;
};

export type InstallationCreateErrorCodes =
  | ExtractErrorCodesFromCommonApiCreateUpdateQuery<CreateInstallation_ekaCreateInstallation>
  | ErrorCode.NOT_FOUND
  | ErrorCode.NO_CREATION_PERMISSION
  | 'NotFoundError'
  | 'AccessDeniedError'
  | 'Error';

export const createInstallation = createAsyncThunk<boolean, InstallationCreatePayload, { rejectValue }>(
  'createInstallation/create',
  async ({ balancingPointId }, { rejectWithValue }) => {
    const apolloClient = getApolloClient();

    try {
      const { data } = await apolloClient.mutate({
        mutation: createInstallationMutation,
        variables: { balancingPointId },
        fetchPolicy: 'no-cache',
      });

      if (data?.ekaCreateInstallation.__typename === 'Installation') {
        return data.ekaCreateInstallation;
      } else if (data?.ekaCreateInstallation.__typename !== 'Installation') {
        if (data.ekaCreateInstallation.errors) {
          return rejectWithValue(data.ekaCreateInstallation.errors);
        }
        return rejectWithValue(data.ekaCreateInstallation.errorCode as InstallationCreateErrorCodes);
      } else {
        return rejectWithValue('Error');
      }
    } catch (error) {
      return rejectWithValue('Error');
    }
  }
);
