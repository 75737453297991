import { getAttributeValueById } from '@utils/assetTypeUtils/getAttributeValueById';
import { Expose } from 'class-transformer';
import { PropertyAsset_asset as PropertyAssetSchema } from '../queries/__generated__/PropertyAsset';
import { ZevAssetsByProperty_assets_AssetsResult_assetsGroupedByAssetType_assets as PropertyAssetWithZevAssetsSchema } from '../queries/__generated__/ZevAssetsByProperty';
import { Model } from '@utils/model.utils';
import { ZevAssetModel } from './ZevAsset.model';
import { addressFormatter } from '@utils/formatter.utils';
import { Address } from '../eka.types';

export class PropertyAssetModel extends Model.fromSchema<PropertyAssetSchema | PropertyAssetWithZevAssetsSchema>() {
  private _address = '';
  private _childrenZevAssets: ZevAssetModel[] = [];

  @Expose()
  get id(): string {
    return this._raw.id;
  }

  @Expose()
  get name(): string {
    return this._raw.name;
  }

  @Expose()
  get type(): string {
    return this._raw.type.id;
  }

  @Expose()
  get address(): string {
    return this._address;
  }

  @Expose()
  get childrenZevAssets(): ZevAssetModel[] {
    return this._childrenZevAssets;
  }

  withData(data: PropertyAssetSchema | PropertyAssetWithZevAssetsSchema): this {
    // prepare address once
    const { attributeValues } = data;
    const { address } = this._config.property;
    const parts = ['street', 'houseNumber', 'postCode', 'city'];
    const addressObject: Partial<Address> = {};

    // We need the return of the first map in order to build the addressObject
    // eslint-disable-next-line
    parts
      .map((part) => `${part}Id` as keyof typeof address)
      .map((id, index) => {
        const attributeValueById = getAttributeValueById(address[id], attributeValues) as unknown as string;
        return (addressObject[parts[index] as keyof Address] = attributeValueById);
      });
    this._address = addressFormatter(addressObject);

    // instantiate child models if necessary
    if ('children' in data && data.children.__typename === 'AssetsResult') {
      this._childrenZevAssets = data.children.assetsGroupedByAssetType.flatMap(({ assets }) =>
        assets.map((asset) =>
          new ZevAssetModel(this._config).withData({
            ...asset,
          })
        )
      );
    }

    // call parent method which will return the locked instance
    return super.withData(data);
  }
}
