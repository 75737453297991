import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { EkaConfig } from '../../eka.types';
import { PropertyAssetModel } from '../../models/PropertyAsset.model';
import { propertyAssetQuery } from '../../queries/propertyAsset.query';

export type PropertyAssetPayload = {
  assetId: string;
  config: EkaConfig;
};

type PropertyAssetErrorCodes = 'ERROR';

export type PropertyAssetResponse = PropertyAssetErrorCodes | PropertyAssetModel;

export const fetchPropertyAsset = createAsyncThunk<
  PropertyAssetResponse,
  PropertyAssetPayload,
  { rejectValue: PropertyAssetErrorCodes }
>('propertyAsset/fetchById', async ({ assetId, config }, { rejectWithValue }) => {
  const apolloClient = getApolloClient();

  try {
    const { data } = await apolloClient.query({
      query: propertyAssetQuery,
      variables: { assetId },
      fetchPolicy: 'cache-first',
    });

    if (data?.asset?.__typename === 'Asset') {
      return new PropertyAssetModel(config).withData(data.asset);
    } else {
      return rejectWithValue('ERROR');
    }
  } catch (error) {
    return rejectWithValue('ERROR');
  }
});
