import { format, isValid } from 'date-fns';
import isNil from 'lodash/isNil';
import { Address } from '../screens/Eka/eka.types';

/**
 * Concat function type to be given as parameter to the time period
 * format factory. It should handle undefined dates as well.
 */
export type TimePeriodFormatConcat = (
  startDateFormatted: string | undefined,
  endDateFormatted: string | undefined,
  startDate: Date | undefined,
  endDate: Date | undefined
) => string;

/**
 * Custom cell formatter for time periods. It allows the property
 * names for the start and end date to be set individually. The
 * concatenation can be customized as well.
 */
export const timePeriodFormatter =
  <T extends { [key in TS | TE]: Date | undefined }, TS extends string = 'startDate', TE extends string = 'endDate'>(
    pattern: string,
    startDateKey: TS = 'startDate' as TS,
    endDateKey: TE = 'endDate' as TE,
    concat: TimePeriodFormatConcat = (start = '', end) => start + (end ? ` - ${end}` : '')
  ): CellFormatter<T> =>
  (...[, , { [startDateKey]: startDate, [endDateKey]: endDate }]) => {
    // invalid patterns cause date-fns to throw, so we handle this inline
    try {
      const startDateFormatted = isValid(startDate) ? format(startDate!, pattern) : undefined;
      const endDateFormatted = isValid(endDate) ? format(endDate!, pattern) : undefined;
      return concat(startDateFormatted, endDateFormatted, startDate, endDate);
    } catch (error) {
      // use the concat function to allow custom implementation for this case as well
      return concat(undefined, undefined, undefined, undefined);
    }
  };

/**
 * Custom cell formatter to unwrap string based values from nested children.
 * @param child
 * @param key
 */
// eslint-disable
// prettier-ignore
export const fromChildFormatter = <T extends { [k in C]: U },
  U extends { [k in K]: R } = any,
  C extends keyof T = keyof T,
  K extends keyof U = keyof U,
  R extends string = string,
  >(child: C, key: K): CellFormatter<T> => (...[, , { [child]: { [key]: value } }]) => value;

// Address formatter
export const addressFormatter = (address: Partial<Address>): string => {
  const { street, houseNumber, postCode, city } = address;
  return (
    [
      [street, houseNumber],
      [postCode, city],
    ]
      // remove incomplete chunks from each line
      .map((line) => line.filter((part) => !isNil(part) && part.trim() !== ''))
      // concat line parts with a space
      .map((line) => line.join(' '))
      // check if anything is left in line
      .filter((line) => line !== '')
      // combine lines with comma
      .join(', ')
  );
};
