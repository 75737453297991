import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorCode } from '@typings/graphql-global';
import { ExtractErrorCodesFromCommonApiCreateUpdateQuery } from '@utils/util.types';
import {
  CreateUtilizationUnitAsset,
  CreateUtilizationUnitAsset_ekaCreateUtilizationUnit,
  CreateUtilizationUnitAsset_ekaCreateUtilizationUnit_Asset,
  CreateUtilizationUnitAssetVariables,
} from '../../queries/__generated__/CreateUtilizationUnitAsset';
import { createUtilizationUnitAssetMutation } from '../../queries/createUtilizationUnitAsset.mutation';

export type UtilizationUnitAssetCreatePayload = {
  name: string;
  zevId: string;
  utilizationUnitTypeId: string;
  originalSapPremiseId?: string | null;
};

export type UtilizationUnitAssetCreateErrorCodes =
  | ExtractErrorCodesFromCommonApiCreateUpdateQuery<CreateUtilizationUnitAsset_ekaCreateUtilizationUnit>
  | ErrorCode.NOT_FOUND
  | ErrorCode.NO_CREATION_PERMISSION
  | 'Error';

export type UtilizationUnitAssetCreateResponse =
  | UtilizationUnitAssetCreateErrorCodes
  | CreateUtilizationUnitAsset_ekaCreateUtilizationUnit_Asset;

export const createUtilizationUnitAsset = createAsyncThunk<
  UtilizationUnitAssetCreateResponse,
  UtilizationUnitAssetCreatePayload,
  { rejectValue: UtilizationUnitAssetCreateErrorCodes }
>(
  'utilizationUnitAsset/create',
  async ({ name, zevId, utilizationUnitTypeId, originalSapPremiseId }, { rejectWithValue, dispatch }) => {
    const apolloClient = getApolloClient();

    try {
      const { data } = await apolloClient.mutate<CreateUtilizationUnitAsset, CreateUtilizationUnitAssetVariables>({
        mutation: createUtilizationUnitAssetMutation,
        variables: {
          utilizationUnitAsset: {
            zevId,
            utilizationUnitTypeId,
            name,
            originalSapPremiseId,
          },
        },
        fetchPolicy: 'no-cache',
      });

      if (data && data.ekaCreateUtilizationUnit.__typename === 'Asset') {
        return data.ekaCreateUtilizationUnit;
      } else {
        return rejectWithValue(
          (data?.ekaCreateUtilizationUnit['__typename'] as UtilizationUnitAssetCreateErrorCodes) ?? 'Error'
        );
      }
    } catch (error) {
      return rejectWithValue('Error');
    }
  }
);
