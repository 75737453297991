import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DeleteBalancingPointAsset,
  DeleteBalancingPointAsset_ekaDeleteBalancingPoint,
  DeleteBalancingPointAssetVariables,
} from '../../queries/__generated__/DeleteBalancingPointAsset';
import { deleteBalancingPointAssetMutation } from '../../queries/deleteBalancingPointAsset.mutation';

export type BalancingPointAssetDeletePayload = { balancingPointId: string };

export type BalancingPointAssetDeleteErrorCodes =
  | Exclude<
      DeleteBalancingPointAsset_ekaDeleteBalancingPoint['__typename'],
      Extract<DeleteBalancingPointAsset_ekaDeleteBalancingPoint['__typename'], 'DeletionResult'>
    >
  | 'Error';

type BalancingPointAssetDeleteResponse = BalancingPointAssetDeleteErrorCodes | boolean;

export const deleteBalancingPointAsset = createAsyncThunk<
  BalancingPointAssetDeleteResponse,
  BalancingPointAssetDeletePayload,
  { rejectValue: BalancingPointAssetDeleteErrorCodes }
>('balancingPointAsset/delete', async ({ balancingPointId }, { rejectWithValue, dispatch }) => {
  const apolloClient = getApolloClient();

  try {
    const { data } = await apolloClient.mutate<DeleteBalancingPointAsset, DeleteBalancingPointAssetVariables>({
      mutation: deleteBalancingPointAssetMutation,
      variables: { balancingPointId },
      fetchPolicy: 'no-cache',
    });

    // Check for successful deletion
    if (data?.ekaDeleteBalancingPoint.__typename === 'DeletionResult') {
      return true;
    }

    // Handle errors if no data or unexpected response
    if (!data) {
      return rejectWithValue('Error');
    }

    return rejectWithValue(data.ekaDeleteBalancingPoint as unknown as BalancingPointAssetDeleteErrorCodes);
  } catch (error) {
    console.error('Error deleting balancing point asset:', error);
    return rejectWithValue('Error');
  }
});
